/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-restricted-syntax */
import React, { useState, Dispatch, SetStateAction, useEffect } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemText, ListItemAvatar, Avatar, Typography, Button } from '@material-ui/core';
import { LocalDining, Storefront, AirportShuttle } from '@material-ui/icons';
import { Swipe } from 'react-swipe-component';
import { useDispatch, useSelector } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Loader from 'react-loader-spinner';
import api from '../../service/api';
import Notification from '../Notification';
import { OptionsContainer } from './styles';
import { IAddressDTO } from '../../interfaces/IAddressDTO';

function Alert(props: AlertProps) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

type IRouteParams = {
  id: string;
  storeid: string;
};

interface IUserLoginDTO {
  name: string;
  cellphone: string;
  customerId: string | null;
}

interface IPropsDTO {
  adresses: IAddressDTO[];
  onConfirm: Dispatch<SetStateAction<string>>;
}

interface INeighborhoodDTO {
  id: string;
  neighborhood: string;
  storeId: string;
  deliveryFee: string;
  delivery_fee?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
  })
);

const AddressOptions = ({ adresses, onConfirm }: IPropsDTO) => {
  const history = useHistory();
  const dispatch: any = useDispatch();
  const params = useParams<IRouteParams>();
  const classes = useStyles();

  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [addressid, setAddressId] = useState<string>('');
  const [isLeft, setIsLeft] = useState<boolean>(false);
  const [style, setStyle] = useState<any[]>([]);
  const [addressesDeliveryArea, setAddressesDeliveryArea] = useState<INeighborhoodDTO[]>([] as INeighborhoodDTO[]);
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState<boolean>();
  const userAuth: IUserLoginDTO = useSelector((state: any) => state.auth);

  const withdrawAddress: IAddressDTO = {
    id: '0',
    street: '',
    houseNumber: '0',
    district: '',
    city: '',
    state: '',
    complement: '',
    reference: '',
    externalCode: '',
    type: 'RETIRADA',
    deliveryFee: '0',
    postalCode: '00000-000',
    deliveryType: 'R',
  };

  const localAddress: IAddressDTO = {
    id: '-1',
    street: '',
    houseNumber: '0',
    district: '',
    city: '',
    state: '',
    complement: '',
    reference: '',
    externalCode: '',
    type: 'LOCAL',
    deliveryFee: '0',
    postalCode: '00000-000',
    deliveryType: 'L',
  };

  useEffect(() => {
    const addressesDeliveryAreaCallback = async () => {
      await api.post(`/address`, { data: { storeId: params.storeid } }).then((res: any) => {
        setAddressesDeliveryArea(res.data);
      });
    };
    addressesDeliveryAreaCallback();
  }, [params]);

  function onSwipeEnd(address: IAddressDTO) {
    if (!isLeft) {
      setIsLeft(false);
      return;
    }

    setIsLeft(false);
    setShowMessage(true);
    setAddressId(address.id);
  }

  function onSwipeLeftListener(index: number, address: IAddressDTO) {
    // eslint-disable-next-line prefer-const
    let styles = [...style];

    const margin = {
      marginLeft: '-230%',
    };

    styles[index] = margin;

    setStyle(styles);

    setIsLeft(false);
    setShowMessage(true);
    setAddressId(address.id);
  }

  function handleCancel(value: any) {
    setShowMessage(value);
    setStyle([{}]);
  }

  function handleConfirmation(value: any) {
    if (!value) {
      return;
    }

    onConfirm(addressid || '');
  }

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  async function handleAddAddress(address: IAddressDTO) {
    setLoader(false);
    if (address.id > '1') {
      address.type = 'DELIVERY';
    }

    for (const addressDeliveryArea of addressesDeliveryArea) {
      if (addressDeliveryArea.neighborhood === address.district) {
        address.deliveryFee = addressDeliveryArea.delivery_fee;
      }
    }

    const dataToVerify = {
      storeId: params.storeid,
      postalCode: address.postalCode,
      numberHouse: address.houseNumber,
    };

    if (address.id > '1') {
      let neighborhoodsIsEquals = false;
      await api.post('/address/check-address', dataToVerify).then(async (resData) => {
        if (resData.data.data.deliveryIn) {
          neighborhoodsIsEquals = true;
        }

        if (!neighborhoodsIsEquals) {
          handleClick();
        } else {
          address.deliveryFee = resData.data.data.price;
        }
      });

      if (!neighborhoodsIsEquals) {
        return;
      }
    }

    const user: IUserLoginDTO = {
      name: userAuth.name,
      cellphone: userAuth.cellphone,
      customerId: address.id,
    };

    setLoader(false);
    await api.post(`/auth`, user).then(() => {
      dispatch({ type: 'ADD_ADDRESS', address });
      dispatch({ type: 'ADD_AUTH', auth: user });
      history.push(`/${params.storeid}/cart`);
    });
  }

  return (
    <>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          Infelizmente não atendemos essa região!
        </Alert>
      </Snackbar>
      <div>
        {loader ? (
          <Loader
            style={{
              marginTop: '15vh',
            }}
            type="RevolvingDot"
            color="#3e3e3e"
            height={600}
            width={100}
          />
        ) : null}
      </div>
      <Notification
        text="Deseja excluir esse endereço?"
        onOpen={showMessage}
        onConfirm={handleConfirmation}
        onClose={handleCancel}
      />
      <OptionsContainer onClick={() => handleAddAddress(localAddress)}>
        <List className={classes.root}>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <LocalDining />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <React.Fragment key="1">
                  <Typography>Local</Typography>
                </React.Fragment>
              }
              secondary={
                <React.Fragment key="1">
                  <Typography>Consumir no local</Typography>
                </React.Fragment>
              }
            />
          </ListItem>
        </List>
      </OptionsContainer>
      <OptionsContainer onClick={() => handleAddAddress(withdrawAddress)}>
        <List className={classes.root}>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <Storefront />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <React.Fragment key="1">
                  <Typography>Retirada</Typography>
                </React.Fragment>
              }
              secondary={
                <React.Fragment key="1">
                  <Typography>Retirar no local</Typography>
                </React.Fragment>
              }
            />
          </ListItem>
        </List>
      </OptionsContainer>
      {!adresses.length ? (
        <Link to="address">
          <OptionsContainer>
            <List className={classes.root}>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <AirportShuttle />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <React.Fragment key="1">
                      <Typography>Entrega</Typography>
                    </React.Fragment>
                  }
                  secondary={
                    <React.Fragment key="1">
                      <Typography>Selecione um endereço.</Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
            </List>
          </OptionsContainer>
        </Link>
      ) : (
        adresses.map((address: IAddressDTO) => (
          <>
            <OptionsContainer style={style[adresses.indexOf(address)]} onClick={() => handleAddAddress(address)}>
              <Swipe
                nodeName="div"
                className="test"
                onSwipeEnd={() => onSwipeEnd(address)}
                onSwipedLeft={() => onSwipeLeftListener(adresses.indexOf(address), address)}
                detectTouch
              >
                <List className={classes.root}>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <AirportShuttle />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <React.Fragment key="1">
                          <Typography>{`${address.street}, ${address.houseNumber}`}</Typography>
                        </React.Fragment>
                      }
                      secondary={
                        <React.Fragment key="1">
                          <Typography>{`${address.district}, ${address.city}-${address.state}`}</Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                </List>
              </Swipe>
            </OptionsContainer>
          </>
        ))
      )}
      {adresses.length ? (
        <Link to="address">
          <Button variant="contained">Adicionar novo endereço</Button>
        </Link>
      ) : null}
    </>
  );
};

export default AddressOptions;
