/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState, useEffect, useCallback } from 'react';
import { FaMapMarkerAlt } from 'react-icons/fa';
import AddressOptions from '../../Components/AddressOptions';
import { HeaderPin, AddressContainer, AddressListContainer } from './styles';
import api from '../../service/api';
import { IAddressDTO } from '../../interfaces/IAddressDTO';
import { ICustomerDTO } from '../../interfaces/ICustomerDTO';

const AddressList = () => {
  // @ts-ignore
  const userDataStorage: ICustomerDTO = JSON.parse(localStorage.getItem('user'));

  const [address, setAddress] = useState<IAddressDTO[]>([]);

  const handleGetAddress = useCallback(async () => {
    await api.get(`/customer-address/${userDataStorage.id}`).then((res: any) => {
      setAddress(res.data as IAddressDTO[]);
    });
  }, [userDataStorage.id]);

  useEffect(() => {
    handleGetAddress();
  }, [handleGetAddress]);

  async function handleDeleteAddress(value: any) {
    if (!value) {
      return;
    }

    await api.delete(`/customer-address/${value}`);

    handleGetAddress();
  }

  return (
    <>
      <AddressListContainer>
        <HeaderPin>
          <FaMapMarkerAlt size={45} style={{ marginTop: 20 }} />
        </HeaderPin>
        <AddressContainer>
          <AddressOptions adresses={address} onConfirm={handleDeleteAddress} />
        </AddressContainer>
      </AddressListContainer>
    </>
  );
};

export default AddressList;
