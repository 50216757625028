import styled from 'styled-components';

export const FormContainer = styled.div`
  margin-top: 15px;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  input {
    color: #000000;
    background: #ffffff;
    border-radius: 5px;
  }

  fieldset {
    border-color: #000000;
  }

  a {
    width: calc(100% - 15px);
    margin-top: 30px;

    button {
      height: 60px !important;
      width: 100% !important;
    }
  }

  button {
    height: 50px !important;
    color: #ffffff !important;
    background: #212121 !important;
    width: calc(100% - 15px) !important;
  }
`;

export const ButtonText = styled.button`
  height: auto !important;
  color: #a8a8a8 !important;
  background: none !important;
  border: none;
  width: auto !important;
`;

export const HeaderLogin = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  position: relative;
  width: 100%;
  height: 95px;
  color: #ffffff;
  background-color: #181818;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.75);
`;

export const OptionsContainer = styled.div`
  width: calc(100% - 15px);
  background-color: #f7f7f7;
  padding: 5px 15px;
  display: flex;
  flex-direction: row;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  margin: 10px;
  align-items: center;
  border-radius: 5px;
  justify-content: space-between;
  a {
    text-decoration: none;
    color: #000000;
    width: 100%;
  }

  ul {
    li {
      div {
        margin-right: -10px;
        margin-left: -3px;
      }
    }
  }

  li {
    padding: 0;
    height: 30px;

    p {
      text-overflow: ellipsis;
      width: calc(100% - 0px);
      white-space: nowrap;
      overflow: hidden;
    }
  }
`;
