import styled from 'styled-components';

export const TextCategory = styled.div`
  width: 100%;
  color: #ffffff;
  background-color: #181818;
  padding: 10px 0;
  text-align: center;
  font-size: 15px;
`;

export const ItemsContainer = styled.div`
  margin: 15px;

  li {
    p {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      white-space: normal;
    }
  }
`;

export const ImgContainer = styled.div`
  height: 150px;
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    z-index: -1;
    position: absolute;
  }
`;

export const BackgroundColorImg = styled.div`
  z-index: -2;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #181818;
`;
