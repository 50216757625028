import styled from 'styled-components';

export const AddressListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-bottom: 50px;

  a {
    color: #ffffff;
    width: 100%;
    text-decoration: none;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  button {
    margin-top: 30px;
    margin-bottom: 15px;
    width: calc(100% - 30px) !important;
  }
`;

export const HeaderPin = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 1;
  position: relative;

  width: 100%;
  height: 90px;
  color: #ffffff;
  background-color: #181818;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.75);
`;

export const AddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
