import styled from 'styled-components';

export const FooterContainer = styled.footer`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0px;
  flex-direction: column;
  align-items: center;

  img {
    width: 130px;
    margin-top: 30px;
    margin-bottom: 30px;
  }
`;

export const HeaderFooterContainer = styled.div`
  background-color: #092431;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px;
  width: calc(100% - 20px);
  height: calc(100% - 20px);

  button {
    border: none;
    background-color: #092431 !important;
    color: #fff;
    height: 20px !important;
    margin: 20px 0px;
    font-size: 16px;
    margin-bottom: 10px;
  }
`;

export const TitleBodyFooter = styled.button`
  border: none;
  color: #a6ce39 !important;
  font-size: 16px;
`;

export const IconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px;
  width: calc(100% - 20px);
  height: calc(100% - 20px);

  svg {
    color: #ffffff;
    margin: 5px 10px;
  }
`;

export const BodyFooterContainer = styled.div`
  background-color: #a6ce39;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 15px;
  width: calc(100% - 30px);
  height: calc(100% - 30px);

  span {
    color: #092431;
    font-size: 10px !important;
  }
`;
