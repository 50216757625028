/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/require-default-props */
import React, { useEffect, useState, Dispatch, SetStateAction, Fragment } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import { FaExclamationCircle } from 'react-icons/fa';

import { NotificationContainer, ButtonsContainer, TextNotificationContainer, TextNotification } from './styles';

interface IPropsDTO {
  text?: string;
  children?: any;
  textConfirmButton?: string;
  onOpen?: boolean;
  onConfirm: Dispatch<SetStateAction<boolean>>;
  onClose: Dispatch<SetStateAction<boolean>>;
}

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});

const Notification = ({
  text,
  textConfirmButton = 'Confirmar',
  onOpen = false,
  onClose,
  onConfirm,
  children,
}: IPropsDTO) => {
  const classes = useStyles();

  const [openNotification, setOpenNotification] = useState<boolean>(onOpen);

  useEffect(() => {
    setOpenNotification(onOpen);
  }, [onOpen]);

  function handleConfirmation() {
    onConfirm(true);
    // onClose(false);
  }

  const anchor = 'bottom';

  const typeViewElement = () => {
    if (!children) {
      return (
        <>
          <FaExclamationCircle />
          <TextNotification>{text || 'DESEJA CONFIRMAR ESSA AÇÃO?'}</TextNotification>
        </>
      );
    }

    return <>{children}</>;
  };

  const list = () => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'bottom',
      })}
      style={{ position: 'relative', height: '100%' }}
      role="presentation"
    >
      <div>
        <TextNotificationContainer>{typeViewElement()}</TextNotificationContainer>

        <ButtonsContainer>
          <Button id="btn-cancel-notification" onClick={() => onClose(false)}>
            Cancelar
          </Button>
          <Button id="btn-confirm-notification" onClick={() => handleConfirmation()}>
            {textConfirmButton}
          </Button>
        </ButtonsContainer>
      </div>
    </div>
  );

  return (
    <NotificationContainer>
      <Fragment key={anchor}>
        <Drawer anchor={anchor} open={openNotification} style={{ height: 600 }}>
          {list()}
        </Drawer>
      </Fragment>
    </NotificationContainer>
  );
};

export default Notification;
