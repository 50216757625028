import styled from 'styled-components';

export const Container = styled.a`
  display: flex;
  flex-direction: row;
  border: 2px solid #dcdcde;
  margin-bottom: 40px;
  border-radius: 60px 60px;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: calc(100% - 20px);
  height: calc(100% - 20px);

  img {
    height: 80px;
    width: 80px;
    border-radius: 200px;
    margin-left: 10px;
  }
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: start;
  justify-content: center;
  padding: 5px;
  margin-left: 10px;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  font-size: 10px;

  span {
    color: #3e3e3f !important;
    margin-bottom: 3px;
    font-size: 12px;
  }
`;

export const BodyText = styled.span`
  color: #3e3e3f;
  font-weight: 600 !important;
  margin-bottom: 3px;
  font-size: 16px;
`;
