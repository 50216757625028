import { createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const INITIAL_STATE = {
  data: [],
  prevPosition: 0,
  address: {},
  auth: {},
  total: 0,
  cartHome: false,
  store: false,
};
let updateAmount: any = [];

const cart = (state: any = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case 'ADD_PRODUCT':
      return { ...state, data: [...state.data, action.product] };

    case 'DELETE_PRODUCT':
      return {
        ...state,
        data: state.data.filter((item: any, index: number) => index !== action.product),
      };

    case 'UPDATE_AMOUNT_PRODUCT':
      updateAmount = state.data.map((item: any) => {
        if (item.vid === action.product.vid) {
          item = action.product;
        }
        return item;
      });

      state.data = updateAmount;
      return { ...state, data: state.data };

    case 'CLEAR_CART':
      state.data = [];
      return { ...state, data: state.data };

    case 'ADD_POSITION_SCROLL':
      return { ...state, position: action.prevPosition };

    case 'ADD_ADDRESS':
      return { ...state, address: action.address };

    case 'ADD_AUTH':
      return { ...state, auth: action.auth };

    case 'ADD_TOTAL':
      return { ...state, total: action.total };

    case 'RESUME_HOME':
      return { ...state, resumeHome: action.resumeHome };

    case 'CLOSED_STORE':
      return { ...state, store: action.store };

    default:
      return state;
  }
};

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['closedStore'],
};
// @ts-ignore
const persistReducerStore = persistReducer(persistConfig, cart);
const store = createStore(persistReducerStore);

const persistor = persistStore(store);

export { store, persistor };
