/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

interface IStoreIsOpenDTO {
  onClose: any;
}

function ModalStoreStatus({ onClose }: IStoreIsOpenDTO) {
  const [open] = React.useState(true);

  return (
    <div>
      <Dialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Fechado no momento!</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            No momento, não estamos aceitando novos pedidos.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ModalStoreStatus;
