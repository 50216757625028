import React, { useState, useEffect } from 'react';
import { FaRegClock } from 'react-icons/fa';
import { BiDetail } from 'react-icons/bi';
import { Grid, Typography } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import ReactWhatsapp from 'react-whatsapp';
import Moment from 'react-moment';
import TimelineDelivery from '../../Components/TimelineDelivery';
import api from '../../service/api';
import WppIcon from '../../assets/images/whatsapp-icon.png';
import 'moment-timezone';
import {
  TimelineItemContainer,
  FooterContainer,
  TextContainer,
  HeaderTime,
  TimerContainer,
  TotalContainer,
  WhatsAppIcon,
  ButtonContainer,
  ButtonContainerHome,
} from './styles';
import cash from '../../util/cash';

type IRouteParams = {
  orderId: string;
  storeid: string;
};

const TimelineItem: React.FC = () => {
  const history = useHistory();
  const params = useParams<IRouteParams>();

  const [valueTotal, setValueTotal] = useState<string>('');
  const [shortReference, setShortReference] = useState<string>('');
  const [minDeliveryTime, setMinDeliveryTime] = useState(0);
  const [maxDeliveryTime, setMaxDeliveryTime] = useState(0);
  const [statusApprovedTime, setStatusApprovedTime] = useState(0);
  const [storeWhatsapp, setStoreWhatsapp] = useState('');
  const [deliveryTypeText, setDeliveryTypeText] = useState('');

  // window.setTimeout(() => window.location.reload(), 6000);

  useEffect(() => {
    async function handleTimeline(): Promise<void> {
      api.get(`/orders/${params.orderId}`).then((response) => {
        setValueTotal(response.data.total);
        setStatusApprovedTime(response.data.createdDate);
        setShortReference(response.data.shortReference);
        setStoreWhatsapp(response.data.storeWhatsapp);
        const { deliveryType } = response.data;
        if (deliveryType === 'R') setDeliveryTypeText('para retirada');
        if (deliveryType === 'L') setDeliveryTypeText('do pedido');
        if (deliveryType === 'E') setDeliveryTypeText('de entrega');
      });

      api.get(`/stores/friendlylink/${params.storeid}`).then((response) => {
        setMinDeliveryTime(Number(response.data.minDeliveryTime));
        setMaxDeliveryTime(Number(response.data.maxDeliveryTime));
      });
    }
    handleTimeline();
  });

  return (
    <TimelineItemContainer>
      <HeaderTime>
        <Typography variant="h5">#{shortReference}</Typography>
        <Typography variant="h5">Previsão {deliveryTypeText}</Typography>
        <TimerContainer>
          <FaRegClock />
          <Moment style={{ paddingRight: '10px' }} add={{ minutes: Number(minDeliveryTime) }} format="LT">
            {statusApprovedTime}
          </Moment>
          {' - '}
          <Moment style={{ paddingLeft: '10px' }} add={{ minutes: Number(maxDeliveryTime) }} format="LT">
            {statusApprovedTime}
          </Moment>
        </TimerContainer>
      </HeaderTime>
      <Grid>
        <TimelineDelivery />
      </Grid>
      <FooterContainer>
        <TotalContainer>
          <TextContainer>Total: {cash(valueTotal)}</TextContainer>
          {storeWhatsapp ? (
            <ReactWhatsapp
              number={storeWhatsapp}
              message={`Olá, realizei o pedido: ${shortReference}, no meu pedido online...`}
            >
              <ButtonContainer>
                <WhatsAppIcon src={WppIcon} alt="whatsapp" />
                <Typography style={{ marginLeft: 10 }}>Entre em Contato</Typography>
              </ButtonContainer>
            </ReactWhatsapp>
          ) : null}
          <ButtonContainerHome onClick={() => history.push(`/${params.storeid}/order-details/${params.orderId}`)}>
            <BiDetail size={30} />
            <Typography style={{ marginLeft: -80 }}>Detalhes</Typography>
          </ButtonContainerHome>
        </TotalContainer>
      </FooterContainer>
    </TimelineItemContainer>
  );
};

export default TimelineItem;
