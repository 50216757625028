/* eslint-disable prefer-destructuring */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Typography, IconButton, Avatar, Menu, MenuItem } from '@material-ui/core';

import { useHistory, Link, useParams } from 'react-router-dom';
import { FiArrowLeft, FiX } from 'react-icons/fi';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import localStorage from 'redux-persist/es/storage';

import { isMobile } from 'react-device-detect';
import Toast from '../Toast';
import api from '../../service/api';
import Logo from '../../assets/Logo.png';
import { NavbarContainer, HeaderNavbar, useStyles } from './styles';

import { IRouteParams } from '../../interfaces/IRouteParamsDTO';

const NavBar: React.FC = () => {
  const params = useParams<IRouteParams>();
  const dispatch = useDispatch();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isDelivery, setIsDelivery] = useState<boolean>(false);
  const [StoreName, setStoreName] = useState('');
  const resumeHome = useSelector((state: any) => state.resumeHome);

  useEffect(() => {
    api.get(`/stores/friendlylink/${params.storeid}`).then((response) => {
      const { data } = response;
      setStoreName(data.name);
      setIsDelivery(data.deliveryActive);
    });
  }, [params.storeid]);

  const classes = useStyles();
  const history = useHistory();

  const hanldeBackButton = () => {
    let route = window.location.pathname.split('/')[2];
    route === 'cart' ? dispatch({ type: 'RESUME_HOME', resumeHome: true }) : null;
    if (resumeHome) {
      dispatch({ type: 'RESUME_HOME', resumeHome: false });
      route = 'resumeHome';
    }

    route === 'login' ? localStorage.removeItem('user') : null;
    route === 'loginorderlist' ? localStorage.removeItem('user') : null;

    switch (route) {
      case 'cart':
        history.push(`/${params.storeid}/resume`);
        break;
      case 'orderlist':
        isMobile ? history.push(`/${params.storeid}`) : history.push(`/${params.storeid}/app`);
        break;
      case 'resume':
        history.goBack();
        // history.push(`/${params.storeid}`);
        break;
      case 'resumeHome':
        isMobile ? history.push(`/${params.storeid}`) : history.push(`/${params.storeid}/app`);
        break;
      case 'loginorderlist':
        isMobile ? history.push(`/${params.storeid}`) : history.push(`/${params.storeid}/app`);
        break;
      case 'login':
        isMobile ? history.push(`/${params.storeid}`) : history.push(`/${params.storeid}/app`);
        break;
      case 'timeline':
        isMobile ? history.push(`/${params.storeid}`) : history.push(`/${params.storeid}/app`);
        break;
      default:
        history.goBack();
        break;
    }
  };

  function headerText() {
    const route = window.location.pathname.split('/')[2];
    switch (route) {
      case 'address':
        return 'ENDEREÇO';
      case 'address-list':
        return 'ENDEREÇOS';
      case 'cart':
        return 'CARRINHO';
      case 'timeline':
        return 'ACOMPANHE SEU PEDIDO';
      case 'resume':
        return 'RESUMO DO PEDIDO';
      case 'login':
        return 'Login';
      case 'details':
        return 'CATEGORIA';
      case 'orderlist':
        return 'PEDIDOS';
      default:
        return '';
    }
  }

  const [menu, setMenu] = React.useState<null | HTMLElement>(null);
  const [toastMsg, setToastMsg] = useState(false);
  const open = Boolean(menu);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setMenu(event.currentTarget);
  };

  const handleOrderList = () => {
    setMenu(null);
    history.push(`/${params.storeid}/authorderlist`);
  };

  const handleClose = () => {
    setMenu(null);
  };

  const handleLogout = () => {
    localStorage.removeItem('user');
    setToastMsg(true);
    window.setTimeout(() => {
      setToastMsg(false);
    }, 1500);
  };

  const homeNavbarWeb = () => {
    return (
      <>
        <HeaderNavbar>
          <img src={Logo} alt="logo" />
          <Typography variant="h6" className={classes.title}>
            {StoreName}
          </Typography>
          <span style={{ marginBottom: '30px' }}>
            <IconButton color="inherit" onClick={handleClick}>
              {isMobile ? (
                <Avatar
                  style={{ height: '35px', width: '34px', marginRight: '0px' }}
                  src="/static/images/avatar/1.jpg"
                />
              ) : null}
            </IconButton>
          </span>
          <Menu anchorEl={menu} open={open} onClose={handleClose}>
            <MenuItem onClick={handleOrderList}>Meus pedidos</MenuItem>
            <MenuItem onClick={handleLogout}>Sair</MenuItem>
            {toastMsg === true ? <Toast type="success" message="Logout Realizado com Sucesso!" /> : null}
          </Menu>
        </HeaderNavbar>
      </>
    );
  };
  const noneNavbar = () => {
    return (
      <>
        <HeaderNavbar>
          <img src={Logo} alt="logo" />
          <Typography variant="h6" className={classes.title}>
            {StoreName}
          </Typography>
          <span style={{ marginBottom: '30px' }}>
            <IconButton color="inherit" onClick={handleClick}>
              <Avatar style={{ height: '35px', width: '34px', marginRight: '0px' }} src="/static/images/avatar/1.jpg" />
            </IconButton>
          </span>
          <Menu anchorEl={menu} open={open} onClose={handleClose}>
            <MenuItem onClick={handleOrderList}>Meus pedidos</MenuItem>
            <MenuItem onClick={handleLogout}>Sair</MenuItem>
            {toastMsg === true ? <Toast type="success" message="Logout Realizado com Sucesso!" /> : null}
          </Menu>
        </HeaderNavbar>
      </>
    );
  };
  function cartIcon() {
    const notCart = true;
    return notCart || window.location.pathname === '/timeline' || window.location.pathname === '' ? (
      <span style={{ marginRight: 35 }} />
    ) : (
      <IconButton color="inherit" aria-label="carrinho" component={Link} to="/cart">
        <ShoppingCartIcon />
      </IconButton>
    );
  }
  function defaultNavbar() {
    if (window.location.pathname.split('/')[1] === 'estabelecimentos') {
      return <></>;
    }
    return (
      <NavbarContainer>
        {window.location.pathname.split('/')[2] !== 'timeline' ? (
          <FiArrowLeft size={25} onClick={hanldeBackButton} />
        ) : (
          <FiX size={25} onClick={hanldeBackButton} />
        )}
        <Typography variant="h6" style={{ marginLeft: '-20px', fontSize: '12px' }}>
          {headerText()}
        </Typography>
        {cartIcon()}
      </NavbarContainer>
    );
  }
  function setTypeNavbar() {
    if (window.location.pathname === `/${params.storeid}`) {
      return homeNavbarWeb();
    }
    if (window.location.pathname === `/${params.storeid}/app`) {
      return noneNavbar();
    }
    return defaultNavbar();
  }
  return <div className={classes.root}>{setTypeNavbar()}</div>;
};

export default NavBar;
