/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';

import Alert from '@material-ui/lab/Alert';
import { useDispatch } from 'react-redux';
import { useStyles } from './styles';

interface NotificationMessage {
  message: string;
}

const NotificationClosedStore = ({ message }: NotificationMessage) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <div className={classes.root}>
      <Alert
        className={classes.sizeAlert}
        onClose={() => {
          // @ts-ignore
          dispatch({ type: 'CLOSED_STORE', store: false });
        }}
        variant="filled"
        severity="info"
        icon={false}
      >
        {message}
      </Alert>
    </div>
  );
};

export { NotificationClosedStore };
