/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

import cash from '../../util/cash';

import { ItemDetailsContainer, ButtonLink } from './styles';

interface IItemDTO {
  id: string;
  name: string;
  image: string;
  description: string;
  price: string;
  content: string;
}

interface IPropsDTO {
  item: IItemDTO;
  navigation: any;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: '#f7f7f7',
    },
  })
);

const ItemDetails = ({ item, navigation }: IPropsDTO) => {
  const history = useHistory();
  const { name, image, description, price }: IItemDTO = item;

  const priceFormated = `${cash(price)}`;

  const classes = useStyles();

  return (
    <ItemDetailsContainer>
      <ButtonLink onClick={() => history.push(navigation)}>
        <List className={classes.root}>
          <ListItem>
            <ListItemText
              primary={
                <React.Fragment key="1">
                  <Typography style={{ fontSize: 18, fontWeight: 500 }}>{name}</Typography>
                </React.Fragment>
              }
              secondary={
                price !== '0.00' ? (
                  <React.Fragment key="1">
                    <Typography style={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography style={{ fontSize: 12 }}>{description}</Typography>
                      <Typography
                        component="span"
                        variant="body2"
                        color="textPrimary"
                        style={{ fontSize: 16, marginTop: 10, fontWeight: 500 }}
                      >
                        {priceFormated}
                      </Typography>
                    </Typography>
                  </React.Fragment>
                ) : null
              }
              style={{ marginRight: 35 }}
            />
            <ListItemAvatar>
              <Avatar>
                <img src={image} alt="item" />
              </Avatar>
            </ListItemAvatar>
          </ListItem>
        </List>
      </ButtonLink>
    </ItemDetailsContainer>
  );
};

export default ItemDetails;
