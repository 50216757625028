/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FaAngleDown } from 'react-icons/fa';
import {
  Container,
  HeaderContainer,
  BodyContainer,
  BodyText,
  OptionsContainer,
  TypesContainer,
  AddressContainer,
  TitleText,
  BodyAddressContainer,
  BodyAddressText,
  ImageHeaderContainer,
  ButtonAddress,
} from './styles';
import Footer from '../../Components/Footer';
import OptionsPreHome from '../../Components/OptionsPreHome';
import logoDark from '../../assets/logo_dark.png';
import ModalPreHome from '../../Components/ModalPreHome';
import api from '../../service/api';
import { IEstablishmentsDTO } from '../../interfaces/IEstablishmentsDTO';

const CitiesPreHome = () => {
  const history = useHistory();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [establishments, setEstablishments] = useState<IEstablishmentsDTO[]>();
  const [cityTop, setCityTop] = useState<string>('');
  const [city, setCity] = useState<string>('');

  const handleCity = useCallback(async () => {
    await api
      .post(`/establishments`, {
        neighborhood: null,
        city: window.location.pathname.split('/')[2].split('-')[1],
        uf: window.location.pathname.split('/')[2].split('-')[2],
      })
      .then((response) => {
        const cityNameUri = decodeURI(window.location.pathname.split('/')[2].split('-')[1]);
        setEstablishments(response.data);
        if (response.data.length === 0) {
          setCityTop(cityNameUri);
          setCity('Não existe restaurante nessa localidade');
        } else {
          setCityTop(cityNameUri);
          setCity(`Restaurantes em ${cityNameUri}`);
        }
      });
  }, []);

  useEffect(() => {
    handleCity();
  }, [handleCity]);

  function handleApp(route: string) {
    history.push(`/${route}`);
  }

  return (
    <Container>
      <HeaderContainer>
        <ImageHeaderContainer>
          <img src={logoDark} alt="" />
        </ImageHeaderContainer>
        <AddressContainer>
          <TitleText> ENTREGAR EM {cityTop.toUpperCase()}</TitleText>
          <BodyAddressContainer>
            <BodyAddressText>Escolha um endereço</BodyAddressText>
            <ButtonAddress type="button" onClick={() => setModalVisible(true)}>
              <FaAngleDown />
            </ButtonAddress>
            {modalVisible ? (
              <ModalPreHome
                onClose={() => {
                  setModalVisible(false);
                }}
              />
            ) : null}
          </BodyAddressContainer>
        </AddressContainer>
      </HeaderContainer>
      <BodyContainer>
        <BodyText>{city}</BodyText>
        <OptionsContainer>
          {establishments
            ? establishments.map((establishment) => (
                <OptionsPreHome
                  onClick={() => {
                    handleApp(establishment.friendlyLink);
                  }}
                  image={establishment.image}
                  title={establishment.title}
                  subtitle={establishment.subtitle}
                  time={`${establishment.time}min`}
                />
              ))
            : null}
        </OptionsContainer>
      </BodyContainer>
      <TypesContainer>
        <span>Escolha à culinária de sua preferência</span>
        <Link to="/">Doces Gourmet</Link>
        <Link to="/">Hamburgueria</Link>
        <Link to="/">Italiana</Link>
        <Link to="/">Japonesa</Link>
        <Link to="/">Pastelaria</Link>
        <Link to="/">Pizzaria</Link>
        <Link to="/">Sorveteria</Link>
        <Link to="/">Vegetariana</Link>
        <Link to="/">Vegana</Link>
      </TypesContainer>

      <Footer />
    </Container>
  );
};

export default CitiesPreHome;
