import styled from 'styled-components';

export const ModalContainer = styled.div`
  position: absolute;
  z-index: 9999999;
  width: 100% !important;
  height: 100% !important;
  left: 0;
  top: 0;
`;

export const Container = styled.div`
  display: flex;
  width: calc(100% - 30px);
  height: calc(100% - 30px);
  padding: 15px;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #fbfbfb;
`;

export const HeaderContainer = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  border-bottom: 1px solid #c4c4c7;
  margin-bottom: 35px;

  svg {
    width: 20px;
    height: 20px;
    color: #092431;
  }

  span {
    font-size: 18px;
    color: #3e3e48;
    margin-right: 30px;
  }
`;

export const CepContainer = styled.div`
  width: 100%;
  text-align: left;
  font-size: 18px;

  span {
    color: #3e3e48;
    margin-bottom: 15px;
  }
`;

export const ButtonClose = styled.button`
  border: 0px solid #0000;
  text-decoration: none !important;
  color: #fff !important;
  background-color: #0000 !important;
  height: 35px !important;
  width: 35px !important;
  padding: 5px;
`;

export const InputContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 35px;

  button {
    height: 100%;
    width: 100px !important;
    border-radius: 0px 10px 10px 0px;
    background-color: #092431 !important;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  svg {
    width: 20px;
    height: 20px;
    color: #a6ce39;
  }
`;

export const FooterContainer = styled.button`
  border: none;
  background-color: #0000 !important;
  color: #092431 !important;
  height: 16px !important;
  margin: 20px 0px;
  font-size: 12px !important;
  width: 100%;

  span {
    color: #092431 !important;
    width: 100%;
    font-size: 16px;
  }
`;

export const FieldsContainer = styled.div`
  input {
    border-radius: 10px !important;
    margin-bottom: 10px !important;
  }

  select {
    height: 50px;
    padding: 10px;
    width: 100%;
    border: 1px solid #b6b7ba;
    border-radius: 10px;
    margin-bottom: 15px;
    backface-visibility: visible;
    background-color: #ffffff;
  }

  button {
    height: 100%;
    background-color: #092431 !important;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    width: 100% !important;
    margin-top: 15px;
    color: #a6ce39 !important;
    font-size: 18px;
  }
`;
