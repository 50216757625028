/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable func-names */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FaSearch } from 'react-icons/fa';
import Carousel from 'react-material-ui-carousel';
import axios from 'axios';
import InputPreHome from '../../Components/InputPreHome';
import Footer from '../../Components/Footer';
import { cepMask } from '../../util/inputMask';
import {
  Container,
  HeaderContainer,
  TitleText,
  InputContainer,
  LastButtonTransparent,
  BodyContainer,
  CarouselContainer,
  BodyText,
  OptionsContainer,
  InformationContainer,
  ItemInformationContainer,
  ImageInformationContainer,
  BodyInformationContainer,
  TitleInformationContainer,
  FooterInformationContainer,
  CitiesContainer,
  TitleCitiesContainer,
} from './styles';
import api from '../../service/api';

import logoDark from '../../assets/logo_dark.png';
import cell from '../../assets/Cell.png';
import OptionsPreHome from '../../Components/OptionsPreHome';
import ModalPreHome from '../../Components/ModalPreHome';
import { IEstablishmentsDTO } from '../../interfaces/IEstablishmentsDTO';

interface ICepDTO {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
  ibge: string;
  gia: string;
  ddd: string;
  siafi: string;
}

interface IFeaturedBanner {
  id: string;
  img_link: string;
}
interface IPropsDTO {
  city: string;
  uf: string;
}

const PreHome = () => {
  const history = useHistory();
  const [cep, setCep] = useState<string>();
  const [cities, setCities] = useState<IPropsDTO[]>([]);
  const [city, setCity] = useState<IPropsDTO | string>();
  const [establishments, setEstablishments] = useState<IEstablishmentsDTO[]>();
  const [featuredBanner, setFeaturedBanner] = useState<IFeaturedBanner[]>();
  const [uf, setUf] = useState<string>();
  const [neighborhood, setNeighborhood] = useState<string>();
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const handleCity = useCallback(async () => {
    await api.get(`/establishments/cities`).then((response) => {
      const citiesData: any = [];
      const filteredCities: any = [];
      response.data.forEach((store: any) => {
        citiesData.push({ city: store.city, uf: store.uf });
      });

      response.data.forEach(function (store: any) {
        let oStore = {};
        let vIsNew = !filteredCities.some(function (item: any) {
          return item.city === store.city && item.uf === store.uf;
        });
        if (!vIsNew) {
          return;
        }
        // @ts-ignore
        oStore.city = store.city;
        // @ts-ignore
        oStore.uf = store.uf;

        filteredCities.push(oStore);
      });

      setCities(filteredCities);
    });
  }, []);

  useEffect(() => {
    handleCity();
  }, [handleCity]);

  function handleClickCity(cityE: string, ufE: string) {
    history.push(`/estabelecimentos/-${cityE}-${ufE}`);
  }

  function filterAddresses() {
    if (cep && cep.replace('-', '').length < 8) {
      history.push('/');
    } else {
      const cepNumber = cep?.replace('-', '');
      axios.get<ICepDTO>(`https://viacep.com.br/ws/${cepNumber}/json/`).then((response) => {
        setCep(response.data.cep);
        setCity(response.data.localidade);
        setNeighborhood(response.data.bairro);
        setUf(response.data.uf);
        if (
          response.data.localidade !== undefined &&
          response.data.uf !== undefined &&
          response.data.bairro !== undefined
        ) {
          history.push(`/estabelecimentos/${response.data.bairro}-${response.data.localidade}-${response.data.uf}`);
        }
      });
    }
  }

  const handleEstablishments = useCallback(async () => {
    await api.get(`/establishments`).then((response) => {
      const parsedData: any = [];
      const isAlreadyAux: any = [];
      response.data.forEach((store: any) => {
        const isAlready = isAlreadyAux.indexOf(store.friendly_link) === -1;
        if (isAlready) {
          isAlreadyAux.push(store.friendly_link);
        } else {
          parsedData.push(store);
        }
      });
      setEstablishments(parsedData);
    });
  }, []);

  useEffect(() => {
    handleEstablishments();
  }, [handleEstablishments]);

  const handleFeaturedBanner = useCallback(async () => {
    await api.get(`/stores/featuredbanner/grfood`).then((response) => {
      setFeaturedBanner(response.data);
    });
  }, []);

  useEffect(() => {
    handleFeaturedBanner();
  }, [handleFeaturedBanner]);

  function handleApp(route: string) {
    history.push(`/${route}`);
  }

  function handleCep(cepE: any) {
    cepMask(cepE);
    setCep(cepE.target.value);
    return cepE;
  }

  return (
    <Container>
      <HeaderContainer>
        <img src={logoDark} alt="" />
        <TitleText>Descubra os melhores cardápios aqui!</TitleText>
        <InputContainer>
          <InputPreHome type="text" placeholder="Digite seu CEP" onChange={(value: string) => handleCep(value)} />
          <button type="button" onClick={() => filterAddresses()}>
            <FaSearch />
          </button>
        </InputContainer>
        <LastButtonTransparent type="button" onClick={() => setModalVisible(true)}>
          Não sei meu cep
        </LastButtonTransparent>
        {modalVisible ? (
          <ModalPreHome
            forgotCep
            onClose={() => {
              setModalVisible(false);
            }}
          />
        ) : null}
      </HeaderContainer>

      <BodyContainer>
        <CarouselContainer>
          <Carousel autoPlay>
            {featuredBanner
              ? featuredBanner.map((imageSrc) => (
                <div>
                  <img
                    style={{ marginTop: '0', width: '100%', height: 'auto' }}
                    key={imageSrc.id}
                    src={imageSrc.img_link}
                    alt="Banner"
                  />
                </div>
              ))
              : null}
          </Carousel>
        </CarouselContainer>
        <BodyText>Os melhores do MeuPedido.online</BodyText>

        <OptionsContainer>
          {establishments
            ? establishments.map((establishment) => (
              <OptionsPreHome
                key={establishment.id}
                onClick={() => {
                  handleApp(establishment.friendlyLink);
                }}
                image={establishment.image}
                title={establishment.title}
                subtitle={establishment.subtitle}
                time={`${establishment.time}min`}
              />
            ))
            : null}
        </OptionsContainer>

        <InformationContainer>
          <ItemInformationContainer>
            <ImageInformationContainer>
              <img key={cell} src={cell} alt="" />
            </ImageInformationContainer>
            <BodyInformationContainer>
              <TitleInformationContainer>Cardápio Online</TitleInformationContainer>
              <span>Cadastre seu restaurante no MeuPedido.Online, ganhe um cardápio inteiramente online.</span>
            </BodyInformationContainer>
          </ItemInformationContainer>
          <FooterInformationContainer>Peça Agora</FooterInformationContainer>
        </InformationContainer>
      </BodyContainer>
      <CitiesContainer>
        <TitleCitiesContainer>Encontre sua cidade</TitleCitiesContainer>
        {cities
          ? cities.map((cityE) => (
            <button key={cityE.city} type="button" onClick={() => handleClickCity(cityE.city, cityE.uf)}>
              {cityE.city}
            </button>
          ))
          : null}
      </CitiesContainer>
      <Footer />
    </Container>
  );
};

export default PreHome;
