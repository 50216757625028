import styled from 'styled-components';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  })
);

export const HeaderNavbar = styled.div`
  background-color: #181818;
  border: none;
  height: 50px;
  padding: 0px 16px;
  display: flex;
  img {
    height: 40px;
    margin-top: 5px;
  }
`;

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  height: 30px;
  width: 100%;
  z-index: 401;
  top: 0px;
  left: 0px;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  filter: drop-shadow(0px 0px 10px black);
  box-shadow: 0px 0px 30px black;
  text-align: center;
  svg {
    margin-left: 15px;
    margin-right: 15px;
  }
  span {
    font-size: 12px;
  }
`;

export const NavContainer = styled.div`
  height: 60px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #f7f7f7;
  width: 100%;
  span {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #2d2d2d;
    svg {
      height: 1.5em;
      width: 1.5em;
    }
  }
`;

export const ContainerButton = styled.div`
  position: fixed;
  bottom: 0px;
  z-index: 6;
  width: 100%;
  .MuiButton-containedPrimary {
    background-color: #181818 !important;
    height: 50px !important;
    width: 100% !important;
    border-radius: 0;
  }
  button {
    span {
      display: flex;
      justify-content: space-between;
    }
  }
`;
