import styled from 'styled-components';

export const TextFieldContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
`;

export const HeaderPin = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 1;
  position: relative;

  width: 100%;
  height: 90px;
  color: #ffffff;
  background-color: #181818;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.75);
`;
