/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';
import 'moment-timezone';
import { makeStyles } from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import { FaThumbtack, FaBox } from 'react-icons/fa';
import { GiConfirmed } from 'react-icons/gi';
import { RiTakeawayFill } from 'react-icons/ri';
import { ImCancelCircle } from 'react-icons/im';
import { IoMdCheckmarkCircle } from 'react-icons/io';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { useParams } from 'react-router-dom';
import api from '../../service/api';
import { TimelineContainer } from './styles';

type IRouteParams = {
  orderId: string;
  storeid: string;
};

const useStyles = makeStyles((theme) => ({
  paperLeft: {
    padding: '2px 4px',
    height: '18px',
    marginLeft: '-25px',
  },
  paperRight: {
    padding: '1px 2px',
    width: '120px',
    height: '40px',
    marginRight: '-60px',
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const TimelineDelivery = () => {
  const classes = useStyles();
  const params = useParams<IRouteParams>();

  const [statusDelivery, setStatusDelivery] = useState<string>('');
  const [statusRecivedTime, setStatusRecivedTime] = useState<string>('');
  const [statusApproved, setStatusApproved] = useState<boolean>(false);
  const [statusApprovedTime, setStatusApprovedTime] = useState<string>('');
  const [statusReady, setStatusReady] = useState<boolean>(false);
  const [statusReadyTime, setStatusReadyTime] = useState('');
  const [statusTraffic, setStatusTraffic] = useState<boolean>(false);
  const [statusTrafficTime, setStatusTrafficTime] = useState('');
  const [statusConcludedTime, setStatusConcludedTime] = useState<string>('');
  const [statusConcluded, setStatusConcluded] = useState<boolean>(false);
  const [statusCanceled, setStatusCanceled] = useState<boolean>(false);

  const handleDelivery = (currentStatus: string) => {
    switch (currentStatus) {
      case 'APPROVED':
        setStatusApproved(true);
        break;
      case 'READY_TRAFFIC':
        setStatusApproved(true);
        setStatusReady(true);
        break;
      case 'TRAFFIC':
        setStatusApproved(true);
        setStatusReady(true);
        setStatusTraffic(true);
        break;
      case 'CONCLUDED':
        setStatusApproved(true);
        setStatusReady(true);
        setStatusTraffic(true);
        setStatusConcluded(true);
        break;
      case 'CANCELED':
        setStatusApproved(false);
        setStatusReady(false);
        setStatusTraffic(false);
        setStatusCanceled(true);
        break;
      default:
        return '';
    }
    return '';
  };

  useEffect(() => {
    let interval = setInterval(() => {
      async function handleTimeline(): Promise<void> {
        await api.get(`/orders/${params.orderId}`).then((response) => {
          setStatusDelivery(response.data.status);
          setStatusRecivedTime(response.data.createdDate);
          setStatusApprovedTime(response.data.approvedDate);
          setStatusReadyTime(response.data.readyDate);
          setStatusTrafficTime(response.data.trafficDate);
          setStatusConcludedTime(response.data.finishedDate);
          handleDelivery(statusDelivery);
        });
      }
      handleTimeline();
    }, 20000);
    return () => clearInterval(interval);
  });

  // setInterval(() => {
  //   async function handleTimeline(): Promise<void> {
  //     await api.get(`/orders/${params.orderId}`).then((response) => {
  //       setStatusDelivery(response.data.status);
  //       setStatusRecivedTime(response.data.createdDate);
  //       setStatusApprovedTime(response.data.approvedDate);
  //       setStatusReadyTime(response.data.readyDate);
  //       setStatusTrafficTime(response.data.trafficDate);
  //       setStatusConcludedTime(response.data.concludedDate);
  //       handleDelivery(statusDelivery);
  //     });
  //   }
  //   handleTimeline();
  // }, 1000);

  useEffect(() => {
    async function handleTimeline(): Promise<void> {
      api.get(`/orders/${params.orderId}`).then((response) => {
        setStatusDelivery(response.data.status);
        setStatusRecivedTime(response.data.createdDate);
        setStatusApprovedTime(response.data.approvedDate);
        setStatusReadyTime(response.data.readyDate);
        setStatusTrafficTime(response.data.trafficDate);
        setStatusConcludedTime(response.data.finishedDate);
        handleDelivery(statusDelivery);
      });
    }
    handleTimeline();
  });

  return (
    <TimelineContainer>
      <Timeline align="alternate">
        <TimelineItem style={{ marginTop: '-75px' }}>
          <TimelineOppositeContent>
            <Typography variant="body2" color="textSecondary">
              {/* @ts-ignore */}
              {/* {Intl.DateTimeFormat('pt-BR').format(statusRecivedTime)} */}
              <Moment date={statusRecivedTime} format="LT" />
            </Typography>
          </TimelineOppositeContent>
          <TimelineSeparator style={{ marginBottom: '30px' }}>
            <TimelineDot color="inherit">
              <FaThumbtack />
            </TimelineDot>
            {statusApproved && <TimelineConnector />}
            {statusCanceled && <TimelineConnector />}
          </TimelineSeparator>
          <TimelineContent>
            <Paper elevation={3} style={{ padding: '2px 4px' }}>
              <Typography>Realizado</Typography>
            </Paper>
          </TimelineContent>
        </TimelineItem>

        {statusApproved === true ? (
          <TimelineItem style={{ marginTop: '-30px' }}>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                <Moment date={statusApprovedTime} format="LT" />
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot color="inherit">
                <GiConfirmed />
              </TimelineDot>
              {statusReady && <TimelineConnector style={{ marginBottom: '30px' }} />}
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paperLeft}>
                <Typography>Confirmado</Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>
        ) : null}

        {statusReady === true ? (
          <TimelineItem style={{ marginTop: '-30px' }}>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                <Moment date={statusReadyTime} format="LT" />
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot color="inherit">
                <FaBox />
              </TimelineDot>
              {statusTraffic && <TimelineConnector style={{ marginBottom: '30px' }} />}
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paperRight}>
                <Typography>
                  Aguardando <br />
                  entregador
                </Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>
        ) : null}

        {statusTraffic === true ? (
          <TimelineItem style={{ marginTop: '-30px' }}>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                <Moment date={statusTrafficTime} format="LT" />
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot color="inherit">
                <RiTakeawayFill />
              </TimelineDot>
              {statusConcluded && <TimelineConnector style={{ marginBottom: '30px' }} />}
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paperLeft}>
                <Typography noWrap={false}>Saiu para entrega</Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>
        ) : null}

        {statusConcluded === true ? (
          <TimelineItem style={{ marginTop: '-30px' }}>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                <Moment date={statusConcludedTime} format="LT" />
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot color="inherit">
                <IoMdCheckmarkCircle />
              </TimelineDot>
              {/* {statusConcluded && <TimelineConnector />} */}
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paperRight}>
                <Typography>
                  Pedido <br /> Finalizado
                </Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>
        ) : null}
        {statusCanceled === true ? (
          <TimelineItem style={{ marginTop: '-30px' }}>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                <Moment date={statusConcludedTime} format="LT" />
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot color="inherit">
                <ImCancelCircle />
              </TimelineDot>
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paperLeft}>
                <Typography noWrap={false}>Cancelado</Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>
        ) : null}
      </Timeline>
    </TimelineContainer>
  );
};

export default TimelineDelivery;
