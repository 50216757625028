import React, { useEffect } from 'react';
// @ts-ignore
import Device from 'react-device-frame';

import { useParams } from 'react-router-dom';

import { Container, ContentContainer } from './styles';

const HomeWeb: React.FC = () => {
  type IRouteParams = {
    storeid: string;
  };
  const params = useParams<IRouteParams>();

  useEffect(() => {
    if (window.location.pathname === '/pizzaprimavera' || window.location.pathname === '/botecoprimavera') {
      window.location.href = 'https://www.meupedido.online/primavera';
    }
  }, [params.storeid]);

  function setViewHome() {
    return (
      <Container>
        <ContentContainer>
          <Device name="iphone-8plus" color="silver" url={`https://dev.meupedido.online/${params.storeid}/app`} />
          {/* <Device name="iphone-8plus" color="silver" url={`http://localhost:3000/${params.storeid}/app`} /> */}
        </ContentContainer>
      </Container>
    );
  }

  return <>{setViewHome()}</>;
};

export default HomeWeb;
