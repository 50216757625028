import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { Grid } from '@material-ui/core';
import emptyCategoryImage from '../../assets/food-5865805_1280.png';
import ItemDetails from '../../Components/ItemDetails';
import api from '../../service/api';
import { TextCategory, ItemsContainer, ImgContainer, BackgroundColorImg } from './styles';

interface IItemDTO {
  id: string;
  name: string;
  image: string;
  description: string;
  price: string;
  content: string;
}

interface IItemDataDTO {
  id: string;
  typecast: string;
  name: string;
  description: string;
  content: null;
  image: string;
  ordernation: number;
  externalCode: string;
  price: string;
  productId: string;
  minimum: number;
  calculation: string;
  status: string;
  primaryLevel: string;
  img: null;
  complementPagination: string;
  imgProductFeatured: string;
}

type IRouteParams = {
  id: string;
  storeid: string;
};

const Details: React.FC = () => {
  const params = useParams<IRouteParams>();
  const [items, setItems] = useState<IItemDTO[] | undefined>(undefined);
  const [fatherItem, setFatherItem] = useState<IItemDataDTO | undefined>(undefined);

  const totalAdd: number = useSelector((stateStore: any) => stateStore.total);

  const [isEmpty, setIsEmpty] = useState(false);

  if (window.location.pathname === `/${params.storeid}/details/${params.id}`) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useScrollPosition(({ currPos }) => {
      localStorage.setItem(`Scroll${params.id}`, JSON.stringify(currPos.y));
    });
  }

  const posY = localStorage.getItem(`Scroll${params.id}`);
  if (posY) {
    const parsedPos = parseInt(posY, 10);
    setTimeout(() => {
      window.scrollTo(0, Math.abs(parsedPos));
    }, 200);
  }

  useEffect(() => {
    async function handleFatherProduct(): Promise<void> {
      api.get(`/products/item/${params.id}`).then((response) => {
        const result = response.data;
        setFatherItem(result);
      });
    }

    handleFatherProduct();

    async function handleChildrenProducts(): Promise<void> {
      api.get(`/products/${params.storeid}/primary/items/${params.id}`).then((response) => {
        if (response.data.length === 0) {
          setIsEmpty(true);
          return;
        }

        setItems(response.data);
      });
    }

    handleChildrenProducts();
  }, [params.id, params.storeid]);

  return (
    <>
      <ImgContainer style={{ display: fatherItem?.imgProductFeatured !== null ? 'flex' : 'none' }}>
        {!fatherItem?.imgProductFeatured && (
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            style={{ backgroundColor: '#000000' }}
          >
            <Loader
              style={{ marginTop: '40px' }}
              type="RevolvingDot"
              color="#FFFF"
              height={100}
              width={100}
              // timeout={3} // 3 secs
            />
          </Grid>
        )}

        <img src={fatherItem?.imgProductFeatured} alt="categoria" />
        <BackgroundColorImg />
      </ImgContainer>

      <TextCategory
        style={
          !fatherItem?.image
            ? { height: 45, display: 'flex', justifyContent: 'flex-end', alignItems: 'center', flexDirection: 'column' }
            : {}
        }
      >
        {fatherItem?.name}
      </TextCategory>
      {isEmpty && (
        <>
          <img style={{ width: '300px', margin: '20px', marginLeft: '40px' }} src={emptyCategoryImage} alt="" />
          <h5 style={{ textAlign: 'center' }}>Estamos adicionando produtos a essa categoria</h5>
        </>
      )}
      {items?.length && (
        <ItemsContainer style={{ paddingBottom: totalAdd > 0 ? 60 : 10 }}>
          {items.map((item: IItemDTO) => (
            <ItemDetails key={item.id} item={item} navigation={`/${params.storeid}/item/${item.id}`} />
          ))}
        </ItemsContainer>
      )}
    </>
  );
};

export default Details;
