import React from 'react';

import { FaFacebookF, FaInstagram, FaYoutube } from 'react-icons/fa';
import logoLight from '../../assets/logo_light.png';
import { BodyFooterContainer, FooterContainer, HeaderFooterContainer, IconsContainer } from './styles';

export const Footer: React.FC = () => {
  // const history = useHistory();
  return (
    <FooterContainer>
      <HeaderFooterContainer>
        <img src={logoLight} alt="" />
        <IconsContainer>
          <FaInstagram />
          <FaFacebookF />
          <FaYoutube />
        </IconsContainer>
      </HeaderFooterContainer>

      <BodyFooterContainer>
        <span>© Copyright 2021 - MeuPedido.Online - Todos os direitos reservados</span>
        <span>MeuPedido.online.com com Agência de Restaurantes Online S.A.</span>
      </BodyFooterContainer>
    </FooterContainer>
  );
};

export default Footer;
