import React from 'react';
import { Redirect, useParams } from 'react-router-dom';

type IRouteParams = {
  storeid: string;
};
const RedirectComponent: React.FC = () => {
  const params = useParams<IRouteParams>();
  return (
    <>
      <Redirect to={`/${params.storeid}`} />
    </>
  );
};

export default RedirectComponent;
