import styled from 'styled-components';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import bgImage from '../../assets/bg.jpeg';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }

  ul {
    border-radius: 0px !important;
    li {
      height: auto !important;
    }
  }

  @media (min-width: 800px) {
    background-image: url('${bgImage}');
    background-repeat: 'repeat';
  }
`;

export const Footer = styled.div`
  width: 100% !important;
  height: auto !important;
  padding: 4px 2px 2px 4px !important;
  background-color: #181818;
  display: flex;
  justify-content: center;
`;

/* NÃO APAGAR */
/* export const Xgh2 = styled.div`
  width: auto !important;
  height: auto !important;
  padding: 75px !important;
`; */

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    '@global': {
      '*::-webkit-scrollbar': {
        width: '0.1em',
        background: 'transparent',
      },
      overflowY: 'hidden',
    },
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'scroll',
      backgroundColor: theme.palette.background.paper,
    },
    gridList: {
      width: '100vw',
      borderRadius: '25px',
      minHeight: '120px',
      height: '100vh',
      position: 'relative',
    },
    icon: {
      color: 'rgba(255, 255, 255, 0.54)',
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  })
);
