/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { useStyles } from './styles';

interface ToastProps {
  message: any;
  type: any;
}

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Toast = ({ message, type }: ToastProps) => {
  const classes = useStyles();
  const [openMessage, setOpenMessage] = React.useState(true);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenMessage(false);
  };

  return (
    <div className={classes.root}>
      <Snackbar open={openMessage} autoHideDuration={1500} onClose={handleClose}>
        <Alert severity={type}>{message}</Alert>
      </Snackbar>
    </div>
  );
};

export default Toast;
