import styled from 'styled-components';

export const FormContainer = styled.div`
  margin-top: 15px;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  input {
    color: #000000;
    background: #ffffff;
    border-radius: 5px;
  }

  fieldset {
    border-color: #000000;
  }

  a {
    width: calc(100% - 15px);
    margin-top: 30px;

    button {
      height: 60px !important;
      width: 100% !important;
    }
  }
  button {
    width: calc(100% - 20px) !important;
    margin-top: 15;
    height: 50px !important;
    color: #ffffff !important;
    background: #212121 !important;
  }
`;

export const HeaderLogin = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  position: relative;
  width: 100%;
  height: 95px;
  color: #ffffff;
  background-color: #181818;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.75);
`;
