import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import 'moment-timezone';
import { useSelector } from 'react-redux';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Loader from 'react-loader-spinner';
import { Grid } from '@material-ui/core';
import { ItemsContainer, HeaderCart } from './styles';
import OrderDetails from '../../Components/OrderDetails';
import { IUserLoginDTO } from '../../interfaces/IUserLoginDTO';
import api from '../../service/api';
import emptyCategoryImage from '../../assets/food-5865805_1280.png';

interface OrderList {
  id: string;
  status: string;
  short_reference: string;
  total: string;
  created_date: Date;
}

type IRouteParams = {
  orderId: string;
  storeid: string;
};

const OrderList: React.FC = () => {
  const params = useParams<IRouteParams>();
  const [isEmpty, setIsEmpty] = useState(false);
  const [items, setItems] = useState<OrderList[] | undefined>(undefined);
  const totalAdd: number = useSelector((stateStore: any) => stateStore.total);
  const [user, setUser] = React.useState<IUserLoginDTO>({} as IUserLoginDTO);

  useEffect(() => {
    async function handleOrderList(): Promise<void> {
      // @ts-ignore
      setUser(JSON.parse(localStorage.getItem('user')));
      api.post(`/profile/orders/`, { userId: user.id }).then((response) => {
        if (Object.keys(response.data).length === 0) {
          setIsEmpty(true);
          return;
        }
        setIsEmpty(false);
        setItems(response.data);
      });
    }
    handleOrderList();
  }, [user.id]);

  return (
    <>
      <HeaderCart>
        <AssignmentIcon style={{ fontSize: 65, marginTop: 20 }} />
      </HeaderCart>
      <Grid container direction="column" justify="center" alignItems="center">
        {!items && !isEmpty ? (
          <Loader
            style={{
              marginTop: '30vh',
            }}
            type="RevolvingDot"
            color="#3e3e3e"
            height={100}
            width={100}
          />
        ) : null}
      </Grid>
      {isEmpty === true ? (
        <>
          <img style={{ width: '300px', margin: '20px', marginLeft: '40px' }} src={emptyCategoryImage} alt="" />
          <h5 style={{ textAlign: 'center' }}>Não há pedidos anteriores disponíveis.</h5>
        </>
      ) : (
        <ItemsContainer style={{ paddingBottom: totalAdd > 0 ? 60 : 10 }}>
          {items
            ? items.map((item: OrderList) => (
                <OrderDetails key={item.id} item={item} navigation={`/${params.storeid}/timeline/${item.id}`} />
              ))
            : null}
        </ItemsContainer>
      )}
    </>
  );
};

export default OrderList;
