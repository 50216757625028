import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'absolute',
      width: '100vw',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
    sizeAlert: {
      fontSize: 12.5,
    },
  })
);
