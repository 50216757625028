import styled from 'styled-components';

export const TimelineItemContainer = styled.div`
  height: 100%;
`;

export const HeaderTime = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 15%;
  width: 100%;
  background-color: #181818;
  color: #ffffff;
  font-size: 24px;
  padding-top: 25px;
`;

export const TimerContainer = styled.div`
  display: flex;
  flex-direction: row;

  svg {
    margin-right: 15px;
  }
`;

export const FooterContainer = styled.div`
  display: flex;
  height: 25%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: 100%;
`;

export const TotalContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: calc(100% - 30px);
  height: 165px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);

  button {
    height: 50px;
    width: calc(100% - 90px) !important;
    font-size: 16px;

    justify-content: center;
    align-items: center;
    border: none;
  }
`;

export const ButtonContainer = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
`;

export const ButtonContainerHome = styled.div`
  justify-content: space-evenly;
  align-items: center;
  display: flex;
  margin-top: 10px;
  background-color: #212121;
  color: #fff;
  width: calc(100% - 90px);
  height: 50px;
  border-radius: 3px;
`;

export const TextContainer = styled.span`
  font-size: 24px;
  margin-bottom: 15px;
`;

export const WhatsAppIcon = styled.img`
  width: 37px;
`;
