import styled from 'styled-components';

export const ItemsContainer = styled.div`
  margin: 15px;

  li {
    p {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      white-space: normal;
    }
  }
`;

export const HeaderCart = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  position: relative;
  width: 100%;
  height: 90px;
  color: #ffffff;
  background-color: #181818;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.75);
`;
