/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { Container, BodyContainer, BodyText } from './styles';

interface IPropsDTO {
  image: string;
  title: string;
  subtitle: string;
  time: string;
  onClick: any;
}

export const OptionsPreHome = ({ image, title, subtitle, time, onClick }: IPropsDTO) => {
  return (
    <Container onClick={onClick}>
      <img src={image} />
      <BodyContainer>
        <BodyText>{title}</BodyText>
        <span>{subtitle}</span>
        <span>{time}</span>
      </BodyContainer>
    </Container>
  );
};
export default OptionsPreHome;
