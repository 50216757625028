import styled from 'styled-components';

export const OptionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  margin-top: 20px;
  margin-left: 15px;
  width: calc(100% - 30px);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
  transition: 0.4s;

  .MuiAvatar-colorDefault {
    background-color: #181818;
  }

  .makeStyles-root-2 {
    background-color: #f7f7f7;
  }

  ul {
    border-radius: 15px;
    padding-top: 0px;
    padding-bottom: 0px;
    max-width: 100% !important;
  }

  .test {
    width: 100%;
  }
`;
