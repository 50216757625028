/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useEffect, useState } from 'react';
import { IconButton, Grid, Typography } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

import { useStyles, ButtonsCountContainer } from './styles';

interface IPropDTO {
  // eslint-disable-next-line react/require-default-props
  countAmount?: number;
  onCount: any;
}

const CounterPizza = ({ onCount, countAmount = 1 }: IPropDTO) => {
  const classes = useStyles();

  const [count, setCount] = useState(0);

  useEffect(() => {
    setCount(countAmount);
  }, [countAmount]);

  function handleCountAmountProduct(action: string): void {
    // eslint-disable-next-line prefer-const
    let amountValue: number = count;
    if (amountValue === 1 && action === 'Increment') {
      return;
    }
    const amount = {
      Increment() {
        setCount((prevCount) => prevCount + 1);
        return amountValue + 1;
      },

      Decrement() {
        if (countAmount === 0) {
          setCount((prevCount) => (prevCount <= 0 ? prevCount : prevCount - 1));
          return amountValue <= 0 ? amountValue : amountValue - 1;
        }

        setCount((prevCount) => (prevCount <= 1 ? prevCount : prevCount - 1));
        return amountValue <= 1 ? amountValue : amountValue - 1;
      },
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: Unreachable code error
    onCount(amount[action]());
  }

  function showCounter() {
    const isDisconnected = false;

    if (isDisconnected) {
      return <></>;
    }

    return (
      <ButtonsCountContainer>
        <IconButton className={classes.Button} onClick={() => handleCountAmountProduct('Decrement')}>
          -
        </IconButton>
        <Typography className={classes.count} variant="h5">
          {count}
        </Typography>
        <IconButton className={classes.Button} onClick={() => handleCountAmountProduct('Increment')}>
          +
        </IconButton>
      </ButtonsCountContainer>
    );
  }

  return <>{showCounter()}</>;
};

export const CounterCart: React.FC = () => {
  const classes = useStyles();
  const [count, setCount] = useState(0);

  // Create handleIncrement event handler
  const handleIncrement = () => {
    setCount((prevCount) => prevCount + 1);
  };

  // Create handleDecrement event handler
  const handleDecrement = () => {
    setCount((prevCount) => prevCount - 1);
  };

  return (
    <Grid direction="row" justify="space-evenly" alignItems="center">
      <Grid className={classes.GridCart} container direction="row" justify="space-evenly" alignItems="center">
        <IconButton className={classes.ButtonCart} onClick={handleDecrement}>
          <RemoveCircleIcon />
        </IconButton>
        <Typography className={classes.count} variant="h5">
          {count}
        </Typography>
        <IconButton className={classes.ButtonCart} onClick={handleIncrement}>
          <AddCircleIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default CounterPizza;
