import React from 'react';
import Login from '../../Components/Login';

const AuthStep: React.FC = () => {
  return (
    <>
      <Login />
    </>
  );
};
export default AuthStep;
