/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { ChangeEvent, useState } from 'react';
import { Button, TextField } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FaUser } from 'react-icons/fa';
import Loader from 'react-loader-spinner';
import { FiLogIn } from 'react-icons/fi';
import api from '../../service/api';
import { FormContainer, HeaderLogin } from './styles';
import Concertina from '../Concertina';
import { IUserLoginDTO } from '../../interfaces/IUserLoginDTO';

type IRouteParams = {
  id: string;
  storeid: string;
};

interface ICreateNewUserDB {
  userId: string;
  storeName: string;
  userName: string;
}

const Login: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams<IRouteParams>();

  const [name, setName] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [nextStep, setNextStep] = useState<boolean>(false);

  const [newUser, setNewUser] = useState<boolean>(false);

  React.useEffect(() => {
    // @ts-ignore
    const userDataStorage = JSON.parse(localStorage.getItem('user'));

    if (userDataStorage !== null) {
      setUserData(userDataStorage);
      setName(userDataStorage.name);
      setPhone(userDataStorage.phone);
    }
  }, []);
  async function authCellphone() {
    if (phone.length <= 13) {
      return;
    }
    const parsedPhone = phone.replace('(', '').replace(')', '').replace('-', '').replace(/\s/g, '');
    await api.post(`/auth/${params.storeid}/${parsedPhone}`).then((res: any) => {
      localStorage.setItem('user', JSON.stringify(res.data));
      setLoading(true);
      setName(res.data.name);
      res.data.name === undefined ? setNewUser(true) : setNewUser(false);
      setNextStep(true);
      setLoading(false);
    });
  }

  function resetUserAuth() {
    dispatch({ type: 'ADD_AUTH', auth: {} });
    dispatch({ type: 'ADD_ADDRESS', address: {} });
  }

  async function authApp() {
    if (name === '') {
      return;
    }
    const parsedPhone = phone.replace('(', '').replace(')', '').replace('-', '').replace(/\s/g, '');
    const user: IUserLoginDTO = { name, phone: parsedPhone, storeId: params.storeid };
    await api
      .post(`/auth`, user)
      .then((res: any) => {
        localStorage.setItem('user', JSON.stringify(res.data));
        return res;
      })
      .then(async (response) => {
        if (!newUser) {
          history.push(`/${params.storeid}/authorderlist`);
          return;
        }
        let createNewUserDB: ICreateNewUserDB = {
          userId: response.data.id,
          storeName: params.storeid,
          userName: response.data.name,
        };
        await api.post('new-user', createNewUserDB).then(() => {
          history.push(`/${params.storeid}/authorderlist`);
        });
      });
  }

  const inputMask = (oEvent: any) => {
    let { value } = oEvent.target;
    let valuePhone = value.replace(/\D/g, '');
    valuePhone = valuePhone.replace(/^0/, '');
    if (valuePhone.length > 10) {
      valuePhone = valuePhone.replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3');
    } else if (valuePhone.length === 7) {
      valuePhone = valuePhone.replace(/^(\d\d)(\d{5})(\d{0,4}).*/, '($1) $2$3');
    } else if (valuePhone.length > 7) {
      valuePhone = valuePhone.replace(/^(\d\d)(\d{5})(\d{0,4}).*/, '($1) $2-$3');
    } else if (valuePhone.length > 2) {
      valuePhone = valuePhone.replace(/^(\d\d)(\d{0,5})/, '($1) $2');
    } else if (valuePhone.length === 0) {
      valuePhone = valuePhone.replace(/^(\d*)/, '$1');
    } else {
      valuePhone = valuePhone.replace(/^(\d*)/, '($1');
    }

    setPhone(valuePhone);
  };

  function viewLogin() {
    // @ts-ignore
    if (!userData.phone) {
      return (
        <>
          {loading && (
            <Loader
              style={{
                marginTop: '-30vh',
              }}
              type="RevolvingDot"
              color="#3e3e3e"
              height={100}
              width={100}
              timeout={10000} // 3 secs
            />
          )}
          <HeaderLogin>
            <FiLogIn style={{ fontSize: 65, marginTop: 20, marginLeft: -25 }} />
          </HeaderLogin>
          <Concertina title="Entrar:" subTitle={!nextStep ? 'Confirme seu Número' : `Confirme seu Nome`} isOpen>
            <FormContainer>
              <TextField
                label="Nome"
                placeholder="Digite seu nome..."
                type="text"
                variant="outlined"
                value={name}
                style={{ display: nextStep ? 'flex' : 'none', marginBottom: 15 }}
                onChange={(event: ChangeEvent<HTMLInputElement>) => setName(event.target.value)}
              />

              <TextField
                label="Telefone"
                placeholder="( )_____-____"
                type="text"
                variant="outlined"
                value={phone}
                style={{ display: !nextStep ? 'flex' : 'none', marginBottom: 15 }}
                onChange={inputMask}
              />

              <Button
                onClick={() => (!nextStep ? authCellphone() : authApp())}
                variant="contained"
                style={{ width: '100vw' }}
                color="primary"
              >
                Próximo
              </Button>
            </FormContainer>
          </Concertina>
        </>
      );
    }

    return (
      <>
        <div
          onClick={() => resetUserAuth()}
          style={{
            backgroundColor: '#f7f7f7',
            padding: '10px 15px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderRadius: 5,
          }}
        >
          <FaUser size={30} />

          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <span>
              {name} | {phone}
            </span>
            <span style={{ fontSize: 12, color: '#a8a8a8' }} aria-hidden="true">
              Não é {name}? Clique aqui
            </span>
          </div>
        </div>
      </>
    );
  }

  return <>{viewLogin()}</>;
};

export default Login;
