/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { Container, useStyles } from './styles';

interface IPropsDTO {
  children: any;
  title: any;
  subTitle: any;
  isOpen: boolean;
  onClick?: any;
  style?: any;
}

const Concertina = ({ children, title, subTitle, isOpen, onClick, style = {} }: IPropsDTO) => {
  const classes = useStyles();

  const [expanded, setExpanded] = useState<boolean>(false);

  useEffect(() => {
    setExpanded(isOpen);
  }, [isOpen]);

  return (
    <Container style={style}>
      <Accordion expanded={expanded}>
        <AccordionSummary
          onClick={onClick}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>{title}</Typography>
          <Typography className={classes.secondaryHeading}>{subTitle}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>{children}</Typography>
        </AccordionDetails>
      </Accordion>
    </Container>
  );
};

export default Concertina;
