import styled from 'styled-components';

export const TimelineContainer = styled.div`
  display: flex;
  justify-content: center;
  height: calc(100% - 400px);
  align-items: center;
  max-height: '30vh';
  ul {
    margin-top: 75px;
  }

  .MuiPaper-root {
    text-align: center;
    color: #ffffff;
    background-color: #181818;
  }

  .makeStyles-secondaryTail-2,
  .makeStyles-secondaryTail-4 {
    background-color: #181818;
  }

  .MuiTimelineDot-defaultPrimary {
    background-color: #0a67a9;
  }

  .MuiTimelineItem-root {
    min-height: 100px;
  }
`;
