import styled from 'styled-components';

export const SubContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  position: relative;

  span {
    font-weight: 550 !important;
    color: #3e3e3f;
  }
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: calc(100% - 20px);
  position: relative;
  margin: 10px 0px;

  span {
    margin: 5px 0px;
    size: 14px !important;
    color: #3e3e3f;
  }
`;

export const Button = styled.button`
  height: 40px !important;
  width: calc(100% - 50px) !important;
  margin-top: 15;
  color: #ffffff !important;
  background: #212121 !important;
`;

export const Received = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15px 0px;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  padding: 15px;
  width: calc(100% - 30px);
  height: calc(100% - 30px);
  border: 1px solid #00fcf8 !important;
  box-shadow: 2px #00fcf8 !important;
  background-color: #f0ffff !important;

  span {
    color: #00b3b0 !important;
  }
`;

export const Approved = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15px 0px;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  padding: 15px;
  width: calc(100% - 30px);
  height: calc(100% - 30px);
  border: 1px solid #f6cc4c !important;
  box-shadow: 2px #f6cc4c !important;
  background-color: #fefbf0 !important;

  span {
    color: #f2ba0d !important;
  }
`;

export const Traffic = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15px 0px;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  padding: 15px;
  width: calc(100% - 30px);
  height: calc(100% - 30px);
  border: 1px solid #236890 !important;
  box-shadow: 2px #236890 !important;
  background-color: #e2f0f8 !important;

  span {
    color: #092431 !important;
  }
`;

export const Concluded = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15px 0px;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  padding: 15px;
  width: calc(100% - 30px);
  height: calc(100% - 30px);
  border: 1px solid #4dff5b !important;
  box-shadow: 2px #4dff5b !important;
  background-color: #f5fff6 !important;

  span {
    color: #00870b !important;
  }
`;
