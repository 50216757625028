/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
/* eslint-disable prettier/prettier */
/* eslint-disable no-debugger */
import React, { useState, useEffect, ChangeEvent } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  Typography,
  CssBaseline,
  Avatar,
  ListItemAvatar,
  Snackbar,
  List,
  ListItemText,
  ListItem,
  TextField,
  InputAdornment,
  Button,
  Grid,
} from '@material-ui/core';
import { LocalDining, Storefront, AirportShuttle, ShoppingCart } from '@material-ui/icons';
import { HiCreditCard } from 'react-icons/hi';
import { FaMoneyBillAlt } from 'react-icons/fa';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import { v4 as uuidv4 } from 'uuid';
import api from '../../service/api';
import Notification from '../../Components/Notification';
import cash from '../../util/cash';
import {
  useStyles,
  Container,
  CardButton,
  TotalContainer,
  TextTotalContainer,
  TypePaymentContainer,
  HeaderCart,
} from './styles';
import { IAddressDTO } from '../../interfaces/IAddressDTO';
import { IPaymentTypeDTO } from '../../interfaces/IPaymentTypeDTO';
import { IItemData as IItemDTO } from '../../interfaces/IItemDataDTO';
import { IRouteParams } from '../../interfaces/IRouteParamsDTO';
import Login from '../../Components/Login';
import { IUserLoginDTO } from '../../interfaces/IUserLoginDTO';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ShoppingCartP: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [user, setUser] = React.useState<IUserLoginDTO>({} as IUserLoginDTO);
  React.useEffect(() => {
    // @ts-ignore
    setUser(JSON.parse(localStorage.getItem('user')));
    api.get('/').then(() => {
      setLoading(false);
    });
  }, []);
  const params = useParams<IRouteParams>();

  const productsCart: IItemDTO[] = useSelector((state: any) => state.data);
  const address: IAddressDTO = useSelector((state: any) => state.address);

  const [thing, setThing] = useState<string>('');

  const [paymentType, setPaymentType] = useState<IPaymentTypeDTO>();
  const [selectPaymentType, setSelectPaymentType] = useState<IPaymentTypeDTO>({} as IPaymentTypeDTO);
  const [paymentTypes] = useState<IPaymentTypeDTO[]>([
    {
      id: '2',
      name: 'Maquininha',
      description: 'Pagar na entrega',
      thing: '',
    },
    {
      id: '1',
      name: 'Dinheiro',
      description: 'Pagar no dinheiro',
      thing: '',
    },
  ]);

  const [products, setProducts] = useState<IItemDTO[]>([]);
  const [loading, setLoading] = useState(true);
  const [loadingTax, setLoadingTax] = useState(true);
  const [subTotal, setSubTotal] = useState<number>(0);
  const [taxa, setTaxa] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);

  const [showMessage, setShowMessage] = useState<boolean>(false);

  const [open, setOpen] = React.useState(false);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    setLoadingTax(true)
    const data = {
      postalCode: String(address.postalCode),
      storeId: String(params.storeid),
      numberHouse: String(address.houseNumber)
    };
    const CheckAddress = async () => {
      await api.post('/address/check-address', data).then((res) => {
        if (res.data.data.price) {
          setTaxa(Number(res.data.data.price));
        } else {
          setTaxa(Number(0));
        }
        setLoadingTax(false);
      });
    }
    CheckAddress();
  }, [params.storeid, address]);

  useEffect(() => {
    let value = 0;

    productsCart.forEach((product) => {
      const { price, amount } = product;

      if (amount && amount > 1) {
        value += Number(price) * amount;
      } else {
        value += Number(price);
      }
    });

    setSubTotal(value);
    setProducts(productsCart);
  }, [productsCart]);

  useEffect(() => {
    setTotal(subTotal + taxa);
    dispatch({ type: 'ADD_TOTAL', total: subTotal + taxa });
  }, [subTotal, taxa, dispatch]);

  const setAddressText = (typeAddressText: string) => {
    switch (typeAddressText) {
      case 'primaryAddressText':
        if (address.id === '0') {
          return 'Retirada';
        }

        if (address.id === '-1') {
          return 'Local';
        }

        return primaryAddressText();

      case 'secondaryddressText':
        if (address.id === '0') {
          return 'Retirar no local';
        }

        if (address.id === '-1') {
          return 'Consumir no local';
        }

        return secondaryddressText();

      default:
        return '';
    }
  };

  const primaryAddressText = () => {
    return address.street !== undefined ? `${address.street}, ${address.houseNumber}` : 'Nenhum endereço adicionado';
  };

  const secondaryddressText = () => {
    return address.district !== undefined
      ? `${address.district}, ${address.city}-${address.state}`
      : 'Adicione um endereço!';
  };

  function handlePaymentType(value: boolean) {
    if (checkThing()) {
      return;
    }

    if (!value) {
      return;
    }

    const { id, name, description } = selectPaymentType;

    setPaymentType({
      id,
      name,
      description,
      thing,
    });
  }

  function checkThing() {
    if (thing === '' || Number(thing) > total || selectPaymentType.id === '2') {
      return false;
    }

    setThing('');
    setOpen(true);
    setShowMessage(true);
    return true;
  }

  async function handleSendInfoCart() {
    let items: any = [];
    for (let i = 0; i < products.length; i++) {
      if (products[i].typecast === 'P') {
        let itemsObj = {
          orderItemId: products[i].vid,
          id: products[i].id,
          typecast: products[i].typecast,
          quantity: products[i].amount,
          parentId: null,
          totalPrice: products[i].totalPrice,
        };
        items.push(itemsObj);
      }
    }
    // @ts-ignore
    for (let i = 0; i < products.length; i++) {
      if (products[i].complements?.length) {
        let itemsObj = {
          orderItemId: uuidv4(),
          // @ts-ignore
          id: products[i].complements[0].parentId,
          // @ts-ignore
          typecast: 'C',
          // @ts-ignore
          quantity: 0,
          // @ts-ignore
          parentId: products[i].vid,
        };
        items.push(itemsObj);
      }
    }

    products.forEach((complements) => {
      complements.complements?.forEach((productComplement) => {
        let itemsObj = {
          orderItemId: uuidv4(),
          id: productComplement.id,
          typecast: productComplement.typecast,
          quantity: productComplement.amount,
          parentId: complements.vid,
        };
        items.push(itemsObj);
      });
    });

    const cart: any = {
      storeId: params.storeid,
      // @ts-ignore
      customerId: user.id,
      customerAddressId: address.id,
      deliveryType: address.deliveryType ? address.deliveryType : 'E',
      paymentForm: selectPaymentType.name.toUpperCase(),
      paymentCardMethod: selectPaymentType.id,
      subtotal: subTotal,
      discount: 0,
      deliveryFee: taxa || 0,
      total,
      cashValue: selectPaymentType.id === '2' ? total : 0,
      changeFor: Number(paymentType?.thing) > 0 ? paymentType?.thing : 0,
      status: 'RECEIVED',
      finishedDate: '',
      comments: '',
      items,
    };

    await api
      .post(`/orders`, cart)
      .then((res: any) => {
        history.push(`/${params.storeid}/timeline/${res.data.id}`);
      })
      .then(() => {
        dispatch({ type: 'CLEAR_CART' });
        dispatch({ type: 'ADD_TOTAL', total: 0 });
      });
  }

  function viewStruct() {
    return (
      <>
        {loadingTax && (
          <Grid>
            <Loader
              style={{
                marginTop: '50vh'
              }}
              type="RevolvingDot"
              color="#3e3e3e"
              height={100}
              width={100}
            />
          </Grid>
        )}
        {!loadingTax && !loading ? (
          <>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
              <Alert onClose={handleClose} severity="error">
                {`Você deve pedir um troco maior que o valor ${cash(total)}`}
              </Alert>
            </Snackbar>

            <HeaderCart>
              <ShoppingCart />
            </HeaderCart>

            <Container>
              <CssBaseline />

              <Login />

              <CardButton onClick={() => history.push(`/${params.storeid}/address-list`)}>
                <List className={classes.root}>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar style={{ background: '#212121' }}>
                        {address.id !== '0' && address.id !== '-1' ? (
                          <AirportShuttle />
                        ) : address.id === '0' ? (
                          <Storefront />
                        ) : (
                          <LocalDining />
                        )}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <React.Fragment key="1">
                          <Typography style={{ textDecoration: 'none' }}>
                            {setAddressText('primaryAddressText')}
                          </Typography>
                        </React.Fragment>
                      }
                      secondary={
                        <React.Fragment key="1">
                          <Typography style={{ textDecoration: 'none' }}>
                            {setAddressText('secondaryddressText')}
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                </List>
              </CardButton>

              <CardButton onClick={() => setShowMessage(true)}>
                <List className={classes.root}>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar style={{ background: '#212121' }}>
                        <HiCreditCard color="white" enableBackground="true" size={30} />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <React.Fragment key="1">
                          <Typography style={{ textDecoration: 'none' }}>
                            {paymentType?.name || 'Nenhum tipo de pagamento'}
                          </Typography>
                        </React.Fragment>
                      }
                      secondary={
                        <React.Fragment key="1">
                          <Typography style={{ textDecoration: 'none' }}>
                            {paymentType?.id === '1'
                              ? paymentType?.description
                              : (thing === '' ? paymentType?.description : `Troco para ${cash(thing)}`) ||
                                'Selecione um tipo'}
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                </List>
              </CardButton>

              <TotalContainer>
                <TextTotalContainer>
                  <Typography style={{ textDecoration: 'none', fontSize: 21, color: '#B2B2B2' }}>Subtotal</Typography>
                  <Typography style={{ textDecoration: 'none', fontSize: 21, color: '#B2B2B2', textAlign: 'right' }}>
                    {cash(subTotal)}
                  </Typography>
                </TextTotalContainer>

                <TextTotalContainer>
                  <Typography style={{ textDecoration: 'none', fontSize: 21, color: '#B2B2B2' }}>Taxa</Typography>

                  <Typography style={{ textDecoration: 'none', fontSize: 21, color: '#B2B2B2', textAlign: 'right' }}>
                    {cash(taxa)}
                  </Typography>
                </TextTotalContainer>
                {thing && (
                  <>
                    <TextTotalContainer>
                      <Typography style={{ textDecoration: 'none', fontSize: 21, color: '#B2B2B2' }}>Troco</Typography>
                      <Typography
                        style={{ textDecoration: 'none', fontSize: 21, color: '#B2B2B2', textAlign: 'right' }}
                      >
                        {cash(thing)}
                      </Typography>
                    </TextTotalContainer>
                  </>
                )}

                <TextTotalContainer>
                  <Typography style={{ textDecoration: 'none', fontSize: 21 }}>Total</Typography>
                  <Typography style={{ textDecoration: 'none', fontSize: 21, textAlign: 'right' }}>
                    {cash(total)}
                  </Typography>
                </TextTotalContainer>
              </TotalContainer>

              {address?.id && paymentType?.id ? (
                <Button
                  onClick={() => handleSendInfoCart()}
                  variant="contained"
                  className={classes.ButtonConfirm}
                  color="primary"
                >
                  Realizar pedido
                </Button>
              ) : (
                <Button disabled className={classes.Button} variant="contained">
                  Realizar pedido
                </Button>
              )}
            </Container>

            <Notification
              onOpen={showMessage}
              onClose={(value: any) => setShowMessage(value)}
              onConfirm={(value: any) => handlePaymentType(value)}
              >
              <TypePaymentContainer style={{ width: '100%' }}>
                {paymentTypes.map((payment: IPaymentTypeDTO) => (
                  <List className={classes.root}>
                    <ListItem
                      style={{
                        transition: '0.2s',
                        boxShadow:
                        selectPaymentType === paymentTypes[paymentTypes.indexOf(payment)]
                        ? 'inset 0px 0px 20px rgba(0, 0, 0, 0.25)'
                        : '0px 0px 10px rgba(0, 0, 0, 0.5)',
                        backgroundColor:
                        selectPaymentType === paymentTypes[paymentTypes.indexOf(payment)] ? '#CCCCCC' : '#F7F7F7',
                      }}
                      onClick={() => {
                        setSelectPaymentType(payment);
                        setThing('');
                      }}
                      >
                      <ListItemAvatar>
                        <Avatar style={{ background: '#212121' }}>
                          <HiCreditCard
                            color="white"
                            enableBackground="true"
                            size={30}
                            style={{ display: payment.id === '1' ? 'flex' : 'none' }}
                            />
                          <FaMoneyBillAlt
                            color="white"
                            enableBackground="true"
                            size={30}
                            style={{ display: payment.id === '2' ? 'flex' : 'none' }}
                            />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <React.Fragment key="1">
                            <Typography style={{ textDecoration: 'none' }}>{payment.name}</Typography>
                          </React.Fragment>
                        }
                        secondary={
                          <React.Fragment key="1">
                            <Typography style={{ textDecoration: 'none' }}>{payment.description}</Typography>
                          </React.Fragment>
                        }
                        />
                    </ListItem>
                  </List>
                ))}

                <div style={{ marginTop: 20 }}>
                  <span>Digite o quanto vai pagar em dinheiro para que o entregador leve o seu troco.</span>
                </div>

                <TextField
                  label="Troco"
                  placeholder="00,00"
                  type="number"
                  variant="outlined"
                  value={thing}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                  }}
                  style={{ marginTop: 10 }}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => setThing(event.target.value)}
                  disabled={selectPaymentType.id !== '1'}
                  />
              </TypePaymentContainer>
            </Notification>
          </>
        ) : null}
      </>
    );
  }

  return <>{viewStruct()}</>;
};

export default ShoppingCartP;
