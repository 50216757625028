import styled from 'styled-components';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const Container = styled.div`
  div.MuiAccordionSummary-root.Mui-expanded {
    min-height: 0px;
  }

  div.MuiAccordionDetails-root {
    padding: 0px;

    p {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
  })
);
