/* eslint-disable prettier/prettier */
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0px;
  flex-direction: column;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #ececee;
  padding: 30px;

  img {
    width: 250px;
    margin-bottom: 35px;
  }
`;

export const TitleText = styled.span`
  color: #3e3e48;
  margin-bottom: 35px;
`;

export const InputContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    width: 100px !important;
    border-radius: 0px 10px 10px 0px;
    background-color: #092431 !important;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  svg {
    width: 20px;
    height: 20px;
    color: #a6ce39;
  }
`;

export const LastButtonTransparent = styled.button`
  border: none;
  background-color: #0000 !important;
  color: #092431 !important;
  height: 16px !important;
  margin: 20px 0px;
  font-size: 12px;
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0px 10px;
  width: calc(100% - 20px);
`;

export const CarouselContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  font-size: 16px;
`;

export const BodyText = styled.span`
  color: #3e3e3f;
  font-size: 20px;
  margin-bottom: 35px;
`;

export const OptionsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 35px;
  margin: 0px 30px;
`;

export const InformationContainer = styled.div`
  width: calc(100%);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0px 30px;
`;

export const ItemInformationContainer = styled.div`
  background-color: #092431;
  display: flex;
  flex-direction: row;
  padding: 10px;
  width: calc(100% - 20px);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px 20px 0px 0px;
  border: none;
  margin-top: 30px;
`;

export const ImageInformationContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding: 0px 10px;
  width: calc(100% - 20px);
  height: 100%;
  margin-bottom: 78px;

  img {
    position: absolute;
    width: 160px;
    top: -24px !important;
    height: 150px;
  }
`;

export const BodyInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  align-items: initial;
  padding: 0px 10px;
  width: calc(100% - 20px);
  height: 100%;
  font-size: 12px;

  span {
    color: #fbfffd;
  }
`;

export const TitleInformationContainer = styled.span`
  color: #a6ce39 !important;
  font-size: 18px !important;
  font-weight: 540 !important;
  margin-bottom: 5px;
`;

export const FooterInformationContainer = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 15px;
  width: calc(100% - 30px);
  height: calc(100% - 30px);
  background-color: #a6ce39;
  font-weight: 700;
  color: #092431;
  border-radius: 0px 0px 20px 20px;
  margin-bottom: 40px;
`;

export const CitiesContainer = styled.div`
  background-color: #ececee;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px;
  width: calc(100% - 20px);
  height: calc(100% - 20px);

  button {
    color: #231f20 !important;
    font-size: 14px !important;
    margin: 10px 0px;
    border: none;
    margin: 10px 0px;
    background-color: transparent !important;
  }
`;

export const TitleCitiesContainer = styled.span`
  color: #092431;
  font-weight: 600;
  font-size: 18px !important;
  margin: 20px 0px;
`;
