import styled from 'styled-components';

export const ItemDetailsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  margin-top: 20px;
  background-color: #f7f7f7;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
  height: 127px;

  padding: 10px 15px;

  ul {
    padding: 0;
  }

  li {
    padding: 0;

    p {
      line-height: 1;
    }

    div {
      span {
        p {
          margin-bottom: 5px;
        }
      }
    }
  }

  .MuiAvatar-root {
    width: 100px;
    height: 100px;
  }

  img {
    height: 100px;
  }
`;

export const ButtonLink = styled.div`
  width: 100%;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
`;

export const ImageView = styled.img`
  width: '100px';
  height: '100px';
  border-radius: '20px';
`;

export const NameContainer = styled.label`
  font-size: 20;
  font-weight: 'bold';
`;
