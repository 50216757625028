/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-syntax */
import React, { ChangeEvent, useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { FaMapMarkerAlt } from 'react-icons/fa';
import axios from 'axios';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

import Loader from 'react-loader-spinner';
import { TextFieldContainer, HeaderPin } from './styles';
import api from '../../service/api';
import { IAddressDTO } from '../../interfaces/IAddressDTO';
import Concertina from '../../Components/Concertina';
import { cepMask } from '../../util/inputMask';
// import Regions from '../../Components/Regions';
import Toast from '../../Components/Toast';

function Alert(props: AlertProps) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

type IRouteParams = {
  id: string;
  storeid: string;
};

interface ICepDTO {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
  ibge: string;
  gia: string;
  ddd: string;
  siafi: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
    ButtonConfirm: {
      width: 'calc(100% - 20px) !important',
      marginTop: 15,
      height: '50px !important',
      color: '#ffffff !important',
      background: '#212121 !important',
    },
  })
);

const Address: React.FC = () => {
  const history = useHistory();
  const params = useParams<IRouteParams>();

  const classes = useStyles();

  const [cepText, setCepText] = useState<string>();
  const [nameText, setNameText] = useState<string>();
  const [referenceText, setReferenceText] = useState<string>();
  const [addressText, setAddressText] = useState<string>('');
  const [deliveryFee, setDeliveryFee] = useState('');
  const [numberText, setNumberText] = useState<string>();
  const [neighborhoodText, setNeighborhoodText] = useState<string>('');
  const [complementText, setComplementText] = useState<string>();
  const [cityText, setCityText] = useState<string>('');
  const [ufText, setUfText] = useState<string>('');

  const [existsNeighborhood, setExistsNeighborhood] = useState<boolean>(false);

  const [open, setOpen] = useState(false);
  const [optionAddressSelected, setOptionAddressSelected] = useState<boolean>(false);

  const [neighborhoodDisabled, setNeighborhoodDisabled] = useState<boolean>(false);
  const [cityDisabled, setCityDisabled] = useState<boolean>(false);
  const [addressDisabled, setAddressDisabled] = useState<boolean>(false);
  const [ufDisabled, setUfDisabled] = useState<boolean>(false);

  const [toast, setToast] = useState<boolean>(false);
  const [messageToast, setMessageToast] = useState<string[]>();
  const [loader, setLoader] = useState<boolean>();
  // @ts-ignore
  const userDataStorage: ICustomerDTO = JSON.parse(localStorage.getItem('user'));

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  useEffect(() => {
    const checkAddress = async () => {
      await api.post(`/address`, { data: { storeId: params.storeid } });
    };

    checkAddress();
  }, []);

  const handleGetCep = async () => {
    setLoader(true);
    if (cepText && cepText.split('').length < 8) {
      setLoader(false);
      return;
    }
    // const dataToVerify = { storeId: params.storeid, postalCode: cepText };
    // await api.post('/address/check-address', dataToVerify).then(async (resData) => {
    await axios.get<ICepDTO>(`https://viacep.com.br/ws/${cepText}/json/`).then((res) => {
      // @ts-ignore
      if (res.data.erro) {
        handleClick();
        setExistsNeighborhood(true);
        setLoader(false);
        return;
      }
      // setDeliveryFee(resData.data.data.zonePrice);
      setCepText(res.data.cep);
      setAddressText(res.data.logradouro);
      setNeighborhoodText(res.data.bairro);
      setCityText(res.data.localidade);
      setUfText(res.data.uf);

      res.data.logradouro?.length > 0 ? setAddressDisabled(true) : setAddressDisabled(false);
      res.data.bairro?.length > 0 ? setNeighborhoodDisabled(true) : setNeighborhoodDisabled(false);
      res.data.localidade?.length > 0 ? setCityDisabled(true) : setCityDisabled(false);
      res.data.uf?.length > 0 ? setUfDisabled(true) : setUfDisabled(false);

      setOptionAddressSelected(true);
      setExistsNeighborhood(false);
    });
    setLoader(false);
    // });
  };

  const handleInsertAddress = async (price: any) => {
    const address: IAddressDTO = {
      id: '0',
      customerId: userDataStorage.id || '',
      name: nameText || '',
      street: addressText || '',
      houseNumber: numberText || '',
      district: neighborhoodText || '',
      complement: complementText || '',
      city: cityText || '',
      state: ufText || '',
      reference: referenceText || '',
      externalCode: '',
      deliveryFee: price || '',
      postalCode: cepText,
    };

    await api.post(`/customer-address/`, address).then(() => {
      setLoader(false);
      history.push(`/${params.storeid}/address-list`);
    });
  };

  const handleCheckAddress = async () => {
    setLoader(true);
    const checkFields = checkFieldValue();
    // @ts-ignore
    if (checkFields.length > 0) {
      // @ts-ignore
      setMessageToast(checkFields);
      setToast(true);
      setLoader(true);
      return;
    }
    const dataToVerify = { storeId: params.storeid, postalCode: cepText, numberHouse: numberText };
    await api.post('/address/check-address', dataToVerify).then(async (resData) => {
      let neighborhoodsIsEquals = false;

      if (resData.data.data.deliveryIn) {
        neighborhoodsIsEquals = true;
      }

      if (!neighborhoodsIsEquals) {
        handleClick();
        setLoader(false);
        return;
      }
      await handleInsertAddress(resData.data.data.price);
      setDeliveryFee(resData.data.data.price);
    });
  };

  const checkFieldValue = () => {
    let arrFilds = [];
    if (addressText === undefined || !addressText) {
      arrFilds.push(' Endereço');
      // return false;
    }

    if (nameText === undefined || !nameText) {
      arrFilds.push(' Nome');
      // return false;
    }

    if (numberText === undefined || !numberText) {
      arrFilds.push(' Número');
      // return false;
    }

    if (neighborhoodText === undefined || !neighborhoodText) {
      arrFilds.push(' Bairro');
      // return false;
    }

    if (cityText === undefined || !cityText) {
      arrFilds.push(' Cidade');
      // return false;
    }

    if (ufText === undefined || !ufText) {
      arrFilds.push(' UF');
      // return false;
    }

    if (cepText === undefined || !cepText) {
      arrFilds.push(' CEP');
      // return false;
    }

    if (arrFilds.length > 0) {
      return [...arrFilds];
    }

    return true;
  };

  const delayTimer = 2.5;
  useEffect(() => {
    let timer = setTimeout(() => setToast(false), delayTimer * 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [toast]);

  function viewAddressRegister() {
    return (
      <>
        <Concertina title="Cadastro" subTitle="Realizar Cadastro" isOpen={optionAddressSelected}>
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="error">
              Infelizmente não atendemos essa região!
            </Alert>
          </Snackbar>

          <form className={classes.root} noValidate autoComplete="off" style={{ paddingBottom: 50 }}>
            <TextFieldContainer>
              <TextField
                disabled={addressDisabled}
                label="Endereço"
                variant="outlined"
                value={addressText}
                onChange={(event: ChangeEvent<HTMLInputElement>) => setAddressText(event.target.value)}
              />

              <TextField
                label="Nº"
                variant="outlined"
                style={{ width: 'calc(100% - 300px)', marginRight: 15, marginLeft: 10 }}
                value={numberText}
                onChange={(event: ChangeEvent<HTMLInputElement>) => setNumberText(event.target.value)}
              />
            </TextFieldContainer>

            <TextField
              disabled={neighborhoodDisabled}
              label="Bairro"
              variant="outlined"
              value={neighborhoodText}
              onChange={(event: ChangeEvent<HTMLInputElement>) => setNeighborhoodText(event.target.value)}
            />

            <TextField
              label="Complemento"
              variant="outlined"
              value={complementText}
              onChange={(event: ChangeEvent<HTMLInputElement>) => setComplementText(event.target.value)}
            />

            <TextFieldContainer>
              <TextField
                disabled={cityDisabled}
                label="Cidade"
                variant="outlined"
                value={cityText}
                onChange={(event: ChangeEvent<HTMLInputElement>) => setCityText(event.target.value)}
              />

              <TextField
                disabled={ufDisabled}
                label="Uf"
                variant="outlined"
                style={{ width: 'calc(100% - 300px)', marginRight: 15, marginLeft: 10 }}
                value={ufText}
                onChange={(event: ChangeEvent<HTMLInputElement>) => setUfText(event.target.value)}
              />
            </TextFieldContainer>

            <TextField
              label="Referência"
              variant="outlined"
              value={referenceText}
              onChange={(event: ChangeEvent<HTMLInputElement>) => setReferenceText(event.target.value)}
            />

            <TextField
              label="Nome"
              variant="outlined"
              value={nameText}
              onChange={(event: ChangeEvent<HTMLInputElement>) => setNameText(event.target.value)}
            />
            <Button variant="contained" onClick={() => handleCheckAddress()}>
              Confirmar endereço
            </Button>
          </form>
          {toast === true ? (
            <Toast type="error" message={`Preencher o(s) seguinte(s) campo(s): ${messageToast}`} />
          ) : null}
        </Concertina>
        <div>
          {loader ? (
            <Loader
              style={{
                marginTop: '15vh',
              }}
              type="RevolvingDot"
              color="#3e3e3e"
              height={600}
              width={100}
            />
          ) : null}
        </div>
      </>
    );
  }

  function handleCepFormated(cep: any) {
    cepMask(cep);
    setCepText(cep.target.value);
    return cep;
  }

  function viewRegisterWithCep() {
    return (
      <>
        <HeaderPin>
          <FaMapMarkerAlt size={45} style={{ marginTop: 20 }} />
        </HeaderPin>
        <div style={{ marginTop: '8%' }}>
          <TextField
            label="CEP"
            variant="outlined"
            value={cepText}
            type="tel"
            onChange={(event: any) => handleCepFormated(event)}
          />

          <div style={{ display: existsNeighborhood ? 'flex' : 'none', fontSize: 12, color: '#d50000' }}>
            *Infelizmente não atendemos esta região.
          </div>

          <Button onClick={() => handleGetCep()} variant="contained" className={classes.ButtonConfirm} color="primary">
            Próximo
          </Button>
        </div>
      </>
    );
  }
  return (
    <>
      {viewRegisterWithCep()}
      {viewAddressRegister()}
    </>
  );
};

export default Address;
