import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';

type IRouteParams = {
  storeid: string;
};
const AuthRedirectComponent: React.FC = () => {
  const params = useParams<IRouteParams>();
  const user = localStorage.getItem('user');
  const history = useHistory();
  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    !user?.length ? history.push(`/${params.storeid}/login`) : history.push(`/${params.storeid}/cart`);
  }, [history, params.storeid, user?.length]);

  return <></>;
};

export default AuthRedirectComponent;
