/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useCallback, useEffect, useState } from 'react';
import { FaRegWindowClose, FaSearch } from 'react-icons/fa';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

import {
  Container,
  HeaderContainer,
  InputContainer,
  FooterContainer,
  ModalContainer,
  ButtonClose,
  CepContainer,
  FieldsContainer,
} from './styles';
import InputPreHome from '../InputPreHome';
import { cepMask } from '../../util/inputMask';

interface IPropsDTO {
  onClose: any;
  // eslint-disable-next-line react/require-default-props
  forgotCep?: boolean;
}
interface ICepDTO {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
  ibge: string;
  gia: string;
  ddd: string;
  siafi: string;
}

const ModalPreHome = ({ onClose, forgotCep = false }: IPropsDTO) => {
  const history = useHistory();

  const [cep, setCep] = useState<string>();
  const [city, setCity] = useState<string>();
  const [uf, setUf] = useState<string>();
  const [neighborhood, setNeighborhood] = useState<string>();
  const [states, setStates] = useState<string[]>();
  const [withoutCep, setWithoutCep] = useState<boolean>(forgotCep);

  const [statesNames, setStatesNames] = useState<string[]>();
  const [cityId, setCityId] = useState<string>('3665474');
  const [cityName, setCityName] = useState<string[]>();

  const searchStates = useCallback(() => {
    axios.get('https://www.geonames.org/childrenJSON?geonameId=3469034').then((response) => {
      let allStates: string[] = [];
      let allCity: string[] = [];

      response.data.geonames.forEach((geoname: any) => {
        allStates.push(geoname.adminName1);
      });
      setStates(allStates);

      response.data.geonames.forEach((geoname: any) => {
        allCity.push(geoname);
      });
      setStatesNames(allCity);
    });
  }, []);

  useEffect(() => {
    searchStates();
  }, [searchStates]);

  const searchStatesCity = useCallback(() => {
    axios.get(`https://www.geonames.org/childrenJSON?geonameId=${cityId}`).then((response) => {
      let allCity: string[] = [];

      response.data.geonames.forEach((geoname: any) => {
        allCity.push(geoname.name);
      });
      setCityName(allCity);
    });
  }, [cityId]);

  useEffect(() => {
    searchStatesCity();
  }, [searchStatesCity]);

  function findAddresses() {
    if (cep && cep.replace('-', '').length < 8) {
      history.push('/');
    } else {
      const cepNumber = cep?.replace('-', '');
      axios
        .get<ICepDTO>(`https://viacep.com.br/ws/${cepNumber}/json/`)
        .then((response) => {
          setCep(response.data.cep);
          setCity(response.data.localidade);
          setNeighborhood(response.data.bairro);
          setUf(response.data.uf);
          if (
            response.data.localidade !== undefined &&
            response.data.uf !== undefined &&
            response.data.bairro !== undefined
          ) {
            // @ts-ignore
            history.push(`/estabelecimentos/${response.data.bairro}-${response.data.localidade}-${response.data.uf}`);
          }
        })
        .then(() => {
          window.location.reload();
        });
    }
  }

  function handleSelectUf(ufE: any) {
    const teste = ufE.currentTarget.value;
    // @ts-ignore
    let teste1 = statesNames.find((element) => element.adminName1 === teste);
    // @ts-ignore
    setUf(teste1.adminCodes1.ISO3166_2);
    // @ts-ignore
    setCityId(teste1.geonameId);
    return ufE;
  }

  function handleSelectCity(cityteste: any) {
    setCity(cityteste.currentTarget.value);
  }
  // onChange={(e) => setUf(states ? states.find((state) => state === e.target.value) : 'AC')}>
  function handleCep(cepE: any) {
    cepMask(cepE);
    setCep(cepE.target.value);
    return cepE;
  }

  function handleConfirmation() {
    history.push(`/estabelecimentos/${neighborhood}-${city}-${uf}`);
    window.location.reload();
  }
  return (
    <ModalContainer>
      <Container>
        <HeaderContainer>
          <span>De onde você quer pedir?</span>
          <ButtonClose type="button" onClick={onClose}>
            <FaRegWindowClose />
          </ButtonClose>
        </HeaderContainer>

        {!withoutCep ? (
          <>
            <CepContainer>
              <span>Buscar endereço</span>
              <InputContainer>
                <InputPreHome type="text" placeholder="Digite seu CEP" onChange={(value: string) => handleCep(value)} />
                <button type="button" onClick={() => findAddresses()}>
                  <FaSearch />
                </button>
              </InputContainer>
            </CepContainer>

            <FooterContainer type="button" onClick={() => setWithoutCep(true)}>
              <span>Não sei meu cep</span>
            </FooterContainer>
          </>
        ) : (
          <>
            <FieldsContainer>
              <select onChange={(value) => handleSelectUf(value)}>
                {states
                  ? states.map((state: string) => (
                      <>
                        <option value={state}>{state}</option>
                      </>
                    ))
                  : null}
              </select>
              <select onChange={(value) => handleSelectCity(value)}>
                {cityName
                  ? cityName.map((state: string) => (
                      <>
                        <option value={state}>{state}</option>
                      </>
                    ))
                  : null}
              </select>
              <InputPreHome type="text" placeholder="Bairro" onChange={(value: string) => setNeighborhood(value)} />
              {/* <button type="button" onClick={() => history.push(`/estabelecimentos/${neighborhood}-${city}-${uf}`)}> */}
              <button type="button" onClick={() => handleConfirmation()}>
                Consultar
              </button>
            </FieldsContainer>
          </>
        )}
      </Container>
    </ModalContainer>
  );
};

export default ModalPreHome;
