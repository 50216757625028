/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { InputPreHomeStyle } from './styles';

interface IPropsDTO {
  onChange: any;
  placeholder: string;
  type: string;
}

const InputPreHome = ({ onChange, placeholder, type }: IPropsDTO) => {
  return (
    <>
      <InputPreHomeStyle type={type} placeholder={placeholder} onChange={(event: any) => onChange(event)} />
    </>
  );
};

export default InputPreHome;
