import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  background-color: #ffffff;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  background-color: #ececee;
  width: calc(100% - 30px);
  height: calc(100% - 30px);
  padding: 15px;
`;

export const ImageHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding: 10px;
  width: calc(100% - 20px);
  height: calc(100% - 20px);

  img {
    width: 100px;
  }
`;

export const AddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  align-items: flex-start;
  padding: 10px;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
`;

export const TitleText = styled.span`
  color: #3e3e48;
  margin-bottom: 5px;
  font-size: 16px;
  text-align: center;
`;

export const BodyAddressContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  align-items: center;

  svg {
    width: 30px;
    height: 30px;
    color: #a6ce39;
  }
`;

export const ButtonAddress = styled.button`
  border: 0px solid #0000;
  text-decoration: none !important;
  color: #fff !important;
  background-color: #0000 !important;
  height: 35px !important;
  width: 35px !important;
  padding: 5px;
`;

export const BodyAddressText = styled.span`
  color: #092431;
  font-weight: 600;
  text-align: left;
  font-size: 14px !important;
`;

export const OptionsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 35px;
  margin: 0px 30px;
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0px 10px;
  width: calc(100% - 20px);
`;

export const BodyText = styled.span`
  margin-top: 20px;
  color: #092431;
  font-size: 22px;
  margin-bottom: 35px;
`;

export const CarouselContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  font-size: 16px;
`;

export const TypesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  font-size: 16px;
  background-color: #ececee;

  span {
    color: #092431;
    font-size: 20px;
    margin-top: 18px;
    margin-bottom: 20px;
    font-weight: 600;
  }

  a {
    cursor: none !important;
    text-decoration: none !important;
    color: #231f20 !important;
    margin-bottom: 10px;
  }
`;
