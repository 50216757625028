/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-expressions */
import React, { ChangeEvent, useState } from 'react';
import { AccountCircle } from '@material-ui/icons';
import {
  Button,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
  createStyles,
  makeStyles,
  InputAdornment,
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Loader from 'react-loader-spinner';
import { FiLogIn } from 'react-icons/fi';
import api from '../../service/api';
import { FormContainer, HeaderLogin, ButtonText, OptionsContainer } from './styles';
import Concertina from '../Concertina';
import { IUserLoginDTO } from '../../interfaces/IUserLoginDTO';
import { IRouteParams } from '../../interfaces/IRouteParamsDTO';

interface ICreateNewUserDB {
  userId: string;
  storeName: string;
  userName: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: '#f7f7f7',
    },
  })
);

const Login: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams<IRouteParams>();
  const classes = useStyles();
  const [name, setName] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [nextStep, setNextStep] = useState<boolean>(false);
  const [newUser, setNewUser] = useState<boolean>(false);

  React.useEffect(() => {
    // @ts-ignore
    const userDataStogare = JSON.parse(localStorage.getItem('user'));

    if (userDataStogare !== null) {
      setUserData(userDataStogare);
      setName(userDataStogare.name);
      setPhone(userDataStogare.phone);
    }
  }, []);
  async function authCellphone() {
    if (phone.length <= 13) {
      return;
    }
    const parsedPhone = phone.replace('(', '').replace(')', '').replace('-', '').replace(/\s/g, '');
    await api.post(`/auth/${params.storeid}/${parsedPhone}`).then((res: any) => {
      localStorage.setItem('user', JSON.stringify(res.data));
      setLoading(true);
      setName(res.data.name);
      res.data.name === undefined ? setNewUser(true) : setNewUser(false);
      setNextStep(true);
      setLoading(false);
    });
  }

  function resetUserAuth() {
    dispatch({ type: 'ADD_AUTH', auth: {} });
  }

  const handleLogout = () => {
    localStorage.removeItem('user');
    dispatch({ type: 'ADD_ADDRESS', address: {} });
    const path = window.location.pathname;
    const isOnCart = path.split('/');
    if (isOnCart[2] === 'cart') {
      history.push(`/${params.storeid}/auth`);
    }
  };

  async function authApp() {
    if (name === '') {
      return;
    }
    const parsedPhone = phone.replace('(', '').replace(')', '').replace('-', '').replace(/\s/g, '');
    const user: IUserLoginDTO = { name, phone: parsedPhone, storeId: params.storeid };
    await api
      .post(`/auth`, user)
      .then((res: any) => {
        localStorage.setItem('user', JSON.stringify(res.data));
        return res;
      })
      .then(async (response) => {
        if (!newUser) {
          history.push(`/${params.storeid}/auth`);
          return;
        }
        let createNewUserDB: ICreateNewUserDB = {
          userId: response.data.id,
          storeName: params.storeid,
          userName: response.data.name,
        };
        await api.post('new-user', createNewUserDB).then((res: any) => {
          history.push(`/${params.storeid}/auth`);
        });
      });
  }

  const inputMask = (oEvent: any) => {
    let { value } = oEvent.target;
    let valuePhone = value.replace(/\D/g, '');
    valuePhone = valuePhone.replace(/^0/, '');
    if (valuePhone.length > 10) {
      valuePhone = valuePhone.replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3');
    } else if (valuePhone.length === 7) {
      valuePhone = valuePhone.replace(/^(\d\d)(\d{5})(\d{0,4}).*/, '($1) $2$3');
    } else if (valuePhone.length > 7) {
      valuePhone = valuePhone.replace(/^(\d\d)(\d{5})(\d{0,4}).*/, '($1) $2-$3');
    } else if (valuePhone.length > 2) {
      valuePhone = valuePhone.replace(/^(\d\d)(\d{0,5})/, '($1) $2');
    } else if (valuePhone.length === 0) {
      valuePhone = valuePhone.replace(/^(\d*)/, '$1');
    } else {
      valuePhone = valuePhone.replace(/^(\d*)/, '($1');
    }
    setPhone(valuePhone);
  };

  function viewLogin() {
    // @ts-ignore
    if (!userData.phone) {
      return (
        <>
          {loading && (
            <Loader
              style={{
                marginTop: '-30vh',
              }}
              type="RevolvingDot"
              color="#3e3e3e"
              height={100}
              width={100}
              timeout={10000} // 3 secs
            />
          )}
          <HeaderLogin>
            <FiLogIn style={{ fontSize: 65, marginTop: 20, marginLeft: -25 }} />
          </HeaderLogin>
          <Concertina title="Entrar:" subTitle={!nextStep ? 'Confirme seu Número' : `Confirme seu Nome`} isOpen>
            <FormContainer>
              <TextField
                label="Nome"
                InputProps={{
                  startAdornment: <InputAdornment position="start"> </InputAdornment>,
                }}
                placeholder="Digite seu nome..."
                type="text"
                variant="outlined"
                value={name}
                style={{ display: nextStep ? 'flex' : 'none', marginBottom: 15 }}
                onChange={(event: ChangeEvent<HTMLInputElement>) => setName(event.target.value)}
              />

              <TextField
                label="Telefone"
                placeholder="( )_____-____"
                type="text"
                variant="outlined"
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                value={phone}
                style={{ display: !nextStep ? 'flex' : 'none', marginBottom: 15 }}
                onChange={inputMask}
              />

              <Button
                onClick={() => (!nextStep ? authCellphone() : authApp())}
                variant="contained"
                style={{ width: '100vw' }}
                color="primary"
              >
                Próximo
              </Button>
            </FormContainer>
          </Concertina>
        </>
      );
    }

    return (
      <>
        <OptionsContainer onClick={handleLogout}>
          <List className={classes.root}>
            <ListItem>
              <ListItemAvatar>
                <Avatar style={{ background: '#212121' }}>
                  <AccountCircle />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <React.Fragment key="1">
                    <Typography>
                      {name} | {phone}
                    </Typography>
                  </React.Fragment>
                }
                secondary={
                  <React.Fragment key="1">
                    <Typography>
                      Não é {name}? <ButtonText>clique aqui</ButtonText>
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
          </List>
        </OptionsContainer>
      </>
    );
  }

  return <>{viewLogin()}</>;
};

export default Login;
