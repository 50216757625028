/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { useHistory } from 'react-router-dom';
import Moment from 'react-moment';
import { SubContainer, BodyContainer, Button, Approved, Concluded, Received, Traffic } from './styles';
import 'moment-timezone';
import 'moment/locale/pt';

import cash from '../../util/cash';

interface OrderList {
  id: string;
  status: string;
  short_reference: string;
  total: string;
  created_date: Date;
}

interface IPropsDTO {
  item: OrderList;
  navigation: any;
}

const OrderDetails = ({ item, navigation }: IPropsDTO) => {
  const history = useHistory();
  const { status, short_reference, total, created_date }: OrderList = item;

  const handleStatusDelivery = (order: string) => {
    switch (order) {
      case 'RECEIVED':
        return 'Realizado';
      case 'APPROVED':
        return 'Confirmado';
      case 'TRAFFIC':
        return 'Saiu para entrega';
      case 'CONCLUDED':
        return 'Pedido Finalizado';
      default:
        return '';
    }
  };

  return (
    <div>
      {status === 'TRAFFIC' ? (
        <Traffic>
          <SubContainer>
            <span>{handleStatusDelivery(status)}</span>
            <span>{cash(total)}</span>
          </SubContainer>
          <BodyContainer>
            <span>#{short_reference}</span>
            <span>
              <Moment locale="pt-br" format="LLL">
                {created_date}
              </Moment>
            </span>
          </BodyContainer>
          <Button onClick={() => history.push(navigation)}>Visualizar</Button>
        </Traffic>
      ) : null}
      {status === 'RECEIVED' ? (
        <Received>
          <SubContainer>
            <span>{handleStatusDelivery(status)}</span>
            <span>{cash(total)}</span>
          </SubContainer>
          <BodyContainer>
            <span>#{short_reference}</span>
            <span>
              <Moment locale="pt-br" format="LLL">
                {created_date}
              </Moment>
            </span>
          </BodyContainer>
          <Button onClick={() => history.push(navigation)}>Visualizar</Button>
        </Received>
      ) : null}
      {status === 'CONCLUDED' ? (
        <Concluded>
          <SubContainer>
            <span>{handleStatusDelivery(status)}</span>
            <span>{cash(total)}</span>
          </SubContainer>
          <BodyContainer>
            <span>#{short_reference}</span>
            <span>
              <Moment locale="pt-br" format="LLL">
                {created_date}
              </Moment>
            </span>
          </BodyContainer>
          <Button onClick={() => history.push(navigation)}>Visualizar</Button>
        </Concluded>
      ) : null}
      {status === 'APPROVED' ? (
        <Approved>
          <SubContainer>
            <span>{handleStatusDelivery(status)}</span>
            <span>{cash(total)}</span>
          </SubContainer>
          <BodyContainer>
            <span>#{short_reference}</span>
            <span>
              <Moment locale="pt-br" format="LLL">
                {created_date}
              </Moment>
            </span>
          </BodyContainer>
          <Button onClick={() => history.push(navigation)}>Visualizar</Button>
        </Approved>
      ) : null}
    </div>
  );
};

export default OrderDetails;
