import styled from 'styled-components';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const Container = styled.div`
  width: 100vw;
  max-height: '100vh';
  padding-bottom: 80px;
`;

export const CardButton = styled.div`
  width: '100vw';
  max-width: '100vw';
  background-color: #f7f7f7;
  padding: 5px 15px;
  display: flex;
  flex-direction: row;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  margin: 10px;
  align-items: center;
  border-radius: 5px;
  justify-content: space-between;
  a {
    text-decoration: none;
    color: #000000;
    width: 100%;
  }

  ul {
    li {
      div {
        margin-right: -10px;
        margin-left: -3px;
      }
    }
  }

  li {
    padding: 0;
    height: 30px;

    p {
      text-overflow: ellipsis;
      width: calc(100% - 0px);
      white-space: nowrap;
      overflow: hidden;
    }
  }
`;

export const InformationsContainer = styled.div`
  margin-right: 25px;
  flex-direction: column;
`;

export const CounterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const NameItem = styled.span`
  font-size: 16px;
  font-weight: 500;
  flex: 1;
`;

export const HeaderCart = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  position: relative;
  width: 100%;
  height: 90px;
  color: #ffffff;
  background-color: #181818;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.75);
`;

export const ComplementsText = styled.span`
  font-size: 12px;
  color: #909090;
  text-align: left;
`;

export const ItemsComplementsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContainerButton = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  .MuiButton-containedPrimary {
    background-color: #181818 !important;
    height: 50px !important;
    width: 100% !important;
    border-radius: 0;
  }
  button {
    span {
      display: flex;
      justify-content: space-between;
    }
  }
`;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},

    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    itemsContainer: {
      marginTop: theme.spacing(2) * 2,
      marginLeft: '20px',
      width: '90vw',
      borderRadius: '200px',
    },
    count: {
      margin: '0 -50px 0 -50px',
    },
    Title: {
      marginBottom: '-20px',
      fontSize: 18,
      fontWeight: 500,
    },
    Description: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '15px',
      maxWidth: '45vw',
    },
    DescriptionText: { fontSize: 12 },
    Grid: {
      marginTop: '30px',
      marginBottom: '20px',
    },
    Button: {
      width: 'calc(100% - 20px) !important',
      marginBottom: 10,
      background: 'grey !important',
    },
    Icon: {
      height: 'auto !important',
      marginLeft: '8px',
      background: '#212121 !important',
      width: 'auto !important',
    },
    GridCart: {
      // marginTop: '30px',
      marginBottom: '10px',

      marginLeft: theme.spacing(5) * 2.6,
    },
    GridItemValue: {
      marginTop: '-70px',

      marginLeft: theme.spacing(7) * 4.4,
    },
    ButtonCart: {
      height: '20px !important',
      width: '20px !important',
      borderRadius: '50%',
    },
  })
);
