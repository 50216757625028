/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ReactWhatsapp from 'react-whatsapp';
import moment from 'moment';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { Typography } from '@material-ui/core';
import {
  Container,
  HeaderContainer,
  BodyContainer,
  TableContainer,
  HeaderTableContainer,
  SubHeaderTableContainer,
  HeaderTable,
  FooterTableContainer,
  FooterTextContainer,
  FooterContainer,
  Title,
  Text,
  TextSpecial,
  ButtonContainer,
} from './styles';
import api from '../../service/api';
import { IOrderDTO } from '../../interfaces/IOrderDTO';
import cash from '../../util/cash';
import { WhatsAppIcon } from '../TimelineItem/styles';
import WppIcon from '../../assets/images/whatsapp-icon.png';

interface IRouteParams {
  orderId: string;
  storeid: string;
}

const OrderDetails = () => {
  const params = useParams<IRouteParams>();
  const [orders, setOrders] = useState<IOrderDTO>();
  const [storeWhatsapp, setStoreWhatsapp] = useState('');
  useEffect(() => {
    api.get(`/orders/${params.orderId}`).then((response) => {
      setStoreWhatsapp(response.data.storeWhatsapp);
      setOrders(response.data);
    });
  }, [params.orderId]);

  function handleStatus(status: string) {
    switch (status) {
      case 'RECEIVED':
        return 'Realizado';
      case 'APPROVED':
        return 'Confirmado';
      case 'TRAFFIC':
        return 'Saiu para entrega';
      case 'CONCLUDED':
        return 'Pedido Finalizado';
      default:
        return '';
    }
  }

  function handleDeliveryType(type: string) {
    switch (type) {
      case 'E':
        return 'ENTREGAR';
      case 'R':
        return 'RETIRAR';
      case 'L':
        return 'LOCAL';
      default:
        return '';
    }
  }

  return (
    <>
      <HeaderContainer>
        <span>Detalhes do pedido #{orders ? orders.shortReference : '#'}</span>
        <AssignmentIcon style={{ fontSize: 50, color: '#FFFF', marginTop: '20px' }} />
      </HeaderContainer>
      <Container>
        <BodyContainer>
          <TableContainer>
            <HeaderTableContainer>
              <span>Pedido: #{orders ? orders.shortReference : '#'}</span>
              <span>Status: {handleStatus(orders ? orders.status : '')}</span>
            </HeaderTableContainer>
            <HeaderTable>
              <Title>Item</Title>
            </HeaderTable>
            {orders
              ? orders.items.map((item) => (
                  <HeaderTable>
                    <Title>{item.name}</Title>
                    {item.SubItems.map((sub) => (
                      <>
                        <SubHeaderTableContainer>
                          {sub.items.map((itemS) => (
                            <div>
                              <span>{itemS.name}</span>
                              <span>{cash(itemS.price)}</span>
                            </div>
                          ))}
                        </SubHeaderTableContainer>
                      </>
                    ))}
                    <Text>{cash(item.price)}</Text>
                  </HeaderTable>
                ))
              : null}
            <FooterTableContainer>
              <FooterTextContainer>
                <Title>SubTotal</Title>
                <Text>{cash(orders ? orders.subtotal : 0)}</Text>
              </FooterTextContainer>
              <FooterTextContainer>
                <Title>Taxa de Entrega</Title>
                <Text>{cash(orders ? orders.deliveryFee : 0)}</Text>
              </FooterTextContainer>
              <FooterTextContainer>
                <Title>Total</Title>
                <Text>{cash(orders ? orders.total : 0)}</Text>
              </FooterTextContainer>
            </FooterTableContainer>
            <FooterContainer>
              <FooterTextContainer>
                <Title>Data do Pedido: </Title>
                <Text>{moment(orders?.createDate).format('DD/MM/YYYY h:mm a')}</Text>
              </FooterTextContainer>
              <FooterTextContainer>
                <Title>Tipo de Entrega: </Title>
                <Text>{handleDeliveryType(orders ? orders.deliveryType : '')}</Text>
              </FooterTextContainer>
              <FooterTextContainer>
                <Title>Pagamento: </Title>
                <TextSpecial>{orders ? orders.payment.paymentForm : ''}</TextSpecial>
              </FooterTextContainer>
            </FooterContainer>
          </TableContainer>
          {storeWhatsapp ? (
            <ReactWhatsapp
              number={storeWhatsapp}
              // @ts-ignore
              message={`Olá, realizei o pedido: ${orders?.shortReference}, no meu pedido online...`}
            >
              <ButtonContainer>
                <WhatsAppIcon src={WppIcon} alt="whatsapp" />
                <Typography style={{ marginLeft: 10 }}>Entre em Contato</Typography>
              </ButtonContainer>
            </ReactWhatsapp>
          ) : null}
        </BodyContainer>
      </Container>
    </>
  );
};

export default OrderDetails;
